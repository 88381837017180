// https://www.color-hex.com/popular-colors.php

import React  from 'react';
import './App.css';
import './Recent.css';
import RecentLang from './RecentLang.json';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

class RecentBankStatementList extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			index: 0,
			count: 100,
			jsonObj: null,
			bankName: "",
			searchText: "",
			minAmount: 0,
			selectedYear: 2024,
			showAllYN: false,
			payYN: true,
			receiveYN: true,
			searchStatementCount: 0,
			payAmountSum: 0,
			receiveAmountSum: 0,
			partnerFullArray: null,
			partnerIbanHashMap: null,
			partnerNameHashMap: null,
			billFullArray: null,
			billNameHashMap: null,
		};
	}

	async componentDidMount() {
		await this.serverGetPartnerList();
		await this.serverGetBillList(this.state.selectedYear);
		this.serverSearchBankStatementList(0, this.state.count, this.state.selectedYear, "", this.state.searchText, this.state.minAmount, true, true);
	}

	async serverSearchBankStatementList(index, count, year, bankName, searchText, minAmount, payYN, receiveYN) {

		this.setState({
			jsonObj: null,
		});

		if (minAmount === "") {
			minAmount = 0;
		}
		
		var pay = 0;
		var receive = 0;
		
		if (payYN) {
			pay = 1;
		}

		if (receiveYN) {
			receive = 1;
		}

		this.serverSearchBankStatementListReturnSum(year, bankName, searchText, minAmount, pay, receive);

		var allBillsYN = this.props.allBillsYN;
		var companyID = this.props.companyID;
		
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/searchbankstatementlist?companyid=" + companyID;
		url = url + "&year=" + year;
		url = url + "&bankname=" + bankName;
		url = url + "&searchtext=" + encodeURIComponent(searchText);
		url = url + "&minamount=" + minAmount;
		url = url + "&pay=" + pay;
		url = url + "&receive=" + receive;
		url = url + "&sortby=executeUnixTimeSec&sortasc=0";
		url = url + "&index=" + index;
		url = url + "&count=" + count;
		
		var res = await fetch(url);
		var jsonObj = await res.json();
		
		this.calcAllStatements(jsonObj);
	}

	calcOneBankStatement(itemObj, billIDUsedHashMap) {

		var partnerIbanHashMap = this.state.partnerIbanHashMap;
		var partnerNameHashMap = this.state.partnerNameHashMap;
		var billNameHashMap = this.state.billNameHashMap;

		itemObj.partnerObj = null;
		itemObj.nameFoundInBillYN = false;
		itemObj.billID = -1;

		if ((partnerIbanHashMap === null) || (billNameHashMap === null) || (partnerNameHashMap === null)) {
			return itemObj;
		}

		var bankStatementID = itemObj.bankStatementID;
		var partnerName = itemObj.partnerName;
		var payAmount = itemObj.payAmount;
		var receiveAmount = itemObj.receiveAmount;
		var payerIBAN = itemObj.payerIBAN;
		var receiverIBAN = itemObj.receiverIBAN;
		var executeDay = itemObj.executeDay;
		var executeMonth = itemObj.executeMonth;
		var executeYear = itemObj.executeYear;

		var iban = "";
		if (payAmount === 0) {
			iban = payerIBAN;
		} else {
			iban = receiverIBAN;
		}

		var partnerObj = partnerIbanHashMap[iban];
		if (typeof partnerObj === "undefined") {
			partnerObj = null;
		}
	
		var i;
		var itemObj2;
		var name;
		var nameArray;
		var billArray;
		var billID = -1;
		var nameFoundInBillYN = false;
		var debugYN = false;

		if (payAmount > 0) {

			if (partnerName === "GAVRILOVIĆ D.O.O.") {
				debugYN = true;
			}

			billArray = this.getBillArrayFromPartnerName(billNameHashMap, partnerNameHashMap, partnerName);
			if (billArray !== null) {
				nameFoundInBillYN = true;
				billID = this.checkIfBillWithAmount(billIDUsedHashMap, billArray, payAmount, executeDay, executeMonth, executeYear);
			}
		}
		
		itemObj.partnerObj = partnerObj;
		itemObj.nameFoundInBillYN = nameFoundInBillYN;
		itemObj.billID = billID;

		return itemObj;
	}

	calcAllStatements(jsonObj) {

		var i;
		var itemObj;
		var billID;
		var billIDUsedHashMap = {};

		for(i=0; i<jsonObj.length; i++) {
			itemObj = jsonObj[i];
			itemObj = this.calcOneBankStatement(itemObj, billIDUsedHashMap);
			billID = itemObj.billID;
			if (billID > 0) {
				billIDUsedHashMap[billID] = true;
			}
			jsonObj[i] = itemObj;
		}

		this.setState({
			jsonObj: jsonObj,
		});
	}

	async serverSearchBankStatementListReturnSum(year, bankName, searchText, minAmount, pay, receive) {

		var companyID = this.props.companyID;
		
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/searchbankstatementlistreturnsum?companyid=" + companyID;
		url = url + "&year=" + year;
		url = url + "&bankname=" + bankName;
		url = url + "&searchtext=" + encodeURIComponent(searchText);
		url = url + "&minamount=" + minAmount;
		url = url + "&pay=" + pay;
		url = url + "&receive=" + receive;

		var res = await fetch(url);
		var jsonObj = await res.json();
	
		try {
			var itemObj = jsonObj[0];

			var searchStatementCount = itemObj.count;
			var payAmountSum = itemObj.payAmountSum;
			var receiveAmountSum = itemObj.receiveAmountSum;
			
			this.setState({
				searchStatementCount: searchStatementCount,
				payAmountSum: payAmountSum,
				receiveAmountSum: receiveAmountSum,
			});
		} catch (err) {
		}
	}
	
	async serverGetBillList(year) {

		var companyID = this.props.companyID;
		
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getbilllist?companyid=" + companyID;
		url = url + "&year=" + year;
		url = url + "&sortby=billID&sortasc=0";
		url = url + "&index=0";
		url = url + "&count=10000000";

		const res = await fetch(url);
		const billFullArray = await res.json();
		
		var i;
		var itemObj1;
		var length = billFullArray.length;
		var billArray;
		var billNameHashMap = {};
		var sellerNameERP;
		
		for(i=0; i<length; i++) {
			itemObj1 = billFullArray[i];
			sellerNameERP = itemObj1.sellerNameERP;
			sellerNameERP = sellerNameERP.trim();
			sellerNameERP = sellerNameERP.toUpperCase();
			if (sellerNameERP !== "") {
				billArray = billNameHashMap[sellerNameERP];
				if (typeof billArray === "undefined") {
					billArray = [];
					billArray.push(itemObj1);
					billNameHashMap[sellerNameERP] = billArray;
				} else {
					billArray.push(itemObj1);
				}
			}
		}


		this.setState({
			billFullArray: billFullArray,
			billNameHashMap: billNameHashMap,
		});		
	}
	
	findInIbanListYN(ibanArray, partnerIbanID1) {

		var i;
		var itemObj;
		var partnerIbanID2;
		
		for(i=0; i<ibanArray.length; i++) {
			itemObj = ibanArray[i];
			partnerIbanID2 = itemObj.partnerIbanID;
			if (partnerIbanID1 === partnerIbanID2) {
				return true;
			}
		}
		
		return false;
	}

	findInNameListYN(nameArray, partnerNameID1) {

		var i;
		var itemObj;
		var partnerNameID2;
		
		for(i=0; i<nameArray.length; i++) {
			itemObj = nameArray[i];
			partnerNameID2 = itemObj.partnerNameID;
			if (partnerNameID1 === partnerNameID2) {
				return true;
			}
		}
		
		return false;
	}

	async serverGetPartnerList() {

		var companyID = this.props.companyID;		
		var baseURLAPI = this.props.baseURLAPI;

		var url = baseURLAPI + "/getpartnerlist?companyid=" + companyID;
		
		var res = await fetch(url);
		var jsonObj = await res.json();
		
		var i;
		var j;
		var itemObj1;
		var partnerIDPrev;
		var partnerID;
		var employeeYN;
		var governmentYN;
		var investmentYN;
		var partnerIbanID;
		var iban;
		var partnerNameID;
		var name;
		var partnerFullArray = [];
		var itemObj2;
		var itemObj3;
		var length = jsonObj.length;
		
		partnerIDPrev = 0;
		
		for(i=0; i<length; i++) {
			itemObj1 = jsonObj[i];
			partnerID = itemObj1.partnerID;
			employeeYN = itemObj1.employeeYN;
			governmentYN = itemObj1.governmentYN;
			investmentYN = itemObj1.investmentYN;
			partnerIbanID = itemObj1.partnerIbanID;
			iban = itemObj1.iban;
			partnerNameID = itemObj1.partnerNameID;
			name = itemObj1.name;
			if (iban === null) {
				iban = "";
			}
			if (name === null) {
				name = "";
			}
			name = name.toUpperCase();
			if (partnerID === partnerIDPrev) {
				if (!this.findInIbanListYN(itemObj2.ibanArray, partnerIbanID)) {
					itemObj3 = {};
					itemObj3.partnerIbanID = partnerIbanID;
					itemObj3.iban = iban;
					itemObj2.ibanArray.push(itemObj3);
				}
				if (!this.findInNameListYN(itemObj2.nameArray, partnerNameID)) {
					itemObj3 = {};
					itemObj3.partnerNameID = partnerNameID;
					itemObj3.name = name;
					itemObj2.nameArray.push(itemObj3);
				}
			} else {
				partnerIDPrev = partnerID;
				itemObj2 = {};
				itemObj2.partnerID = partnerID;
				if (employeeYN === 1) {
					itemObj2.employeeYN = true;
				} else {
					itemObj2.employeeYN = false;
				}
				if (governmentYN === 1) {
					itemObj2.governmentYN = true;
				} else {
					itemObj2.governmentYN = false;
				}
				if (investmentYN === 1) {
					itemObj2.investmentYN = true;
				} else {
					itemObj2.investmentYN = false;
				}
				itemObj2.openYN = false;
				itemObj2.ibanArray = [];
				itemObj3 = {};
				itemObj3.partnerIbanID = partnerIbanID;
				itemObj3.iban = iban;
				itemObj2.ibanArray.push(itemObj3);
				itemObj2.nameArray = [];
				itemObj3 = {};
				itemObj3.partnerNameID = partnerNameID;
				itemObj3.name = name;
				itemObj2.nameArray.push(itemObj3);
				partnerFullArray.push(itemObj2);
			}
		}
		
		var partnerIbanHashMap = {};
		var partnerNameHashMap = {};
		var ibanArray;
		var nameArray;
		
		for(i=0; i<partnerFullArray.length; i++) {
			itemObj1 = partnerFullArray[i];
			ibanArray = itemObj1.ibanArray;
			for(j=0; j<ibanArray.length; j++) {
				itemObj2 = ibanArray[j];
				iban = itemObj2.iban;
				partnerIbanHashMap[iban] = itemObj1;
			}
			nameArray = itemObj1.nameArray;
			for(j=0; j<nameArray.length; j++) {
				itemObj2 = nameArray[j];
				name = itemObj2.name;
				name = name.toUpperCase();
				partnerNameHashMap[name] = itemObj1;
			}
		}

		this.setState({
			partnerFullArray: partnerFullArray,
			partnerIbanHashMap: partnerIbanHashMap,
			partnerNameHashMap: partnerNameHashMap,
		});
	}

	getTodayDateString() {
		
		var today = new Date();
		var year = today.getFullYear();
		var month = today.getMonth() + 1;
		var day = today.getDate();		
		var dateString = year + "-" + this.props.getTwoDigits(month) + "-" + this.props.getTwoDigits(day);
		
		return dateString;
	}
	
	convertFloatToValueWithColon(floatNumber) {
		var string1 = floatNumber.toString();
		var string2 = string1.replace(".", ",");
		return string2;
	}

	onSearchTextChanged(value) {
		var searchText = value;
		this.setState({
			index: 0,
			searchText: searchText,
		});
		var count = this.resetCount();
		this.serverSearchBankStatementList(this.state.index, count, this.state.selectedYear, this.state.bankName, searchText, this.state.minAmount, this.state.payYN, this.state.receiveYN);
	}
	
	onMinAmountChanged(value) {

		var minAmount = "";
		try {
			minAmount = parseInt(value);
		} catch (err) {
		}
		
		if (isNaN(minAmount)) {
			minAmount = "";
		}
		this.setState({
			index: 0,
			minAmount: minAmount,
		});
		var count = this.resetCount();
		this.serverSearchBankStatementList(this.state.index, count, this.state.selectedYear, this.state.bankName, this.state.searchText, minAmount, this.state.payYN, this.state.receiveYN);
	}
	
	async onSelectedYearChanged(value) {
		var selectedYear = parseInt(value);
		this.setState({selectedYear: selectedYear});
		var count = this.resetCount();
		await this.serverGetBillList(selectedYear);
		this.serverSearchBankStatementList(this.state.index, count, selectedYear, this.state.bankName, this.state.searchText, this.state.minAmount, this.state.payYN, this.state.receiveYN);
	}
	
	onPayReceiveChanged(value) {
		
		var payYN = false;
		var receiveYN = false;
		
		if (value === "") {
			payYN = true;
			receiveYN = true;
		}

		if (value === "pay") {
			payYN = true;
		}

		if (value === "receive") {
			receiveYN = true;
		}

		this.setState({
			payYN: payYN,
			receiveYN: receiveYN,
		});

		var count = this.resetCount();
		this.serverSearchBankStatementList(this.state.index, count, this.state.selectedYear, this.state.bankName, this.state.searchText, this.state.minAmount, payYN, receiveYN);
	}
	
	onBankNameChanged(value) {
		var bankName = value;
		this.setState({bankName: bankName});
		var count = this.resetCount();
		this.serverSearchBankStatementList(this.state.index, count, this.state.selectedYear, bankName, this.state.searchText, this.state.minAmount, this.state.payYN, this.state.receiveYN);
	}

	renderYearDropdown() {
		
		var selectedYear = this.state.selectedYear;
		
		return (
			<select 
				id="searchYear" 
				key="searchYear"
				value={selectedYear}
				onChange={(event) => this.onSelectedYearChanged(event.target.value)}
				style={{ padding: '8px', width: '100px' }}
			>
				<option value="2022">2022</option>);
				<option value="2023">2023</option>);
				<option value="2024">2024</option>);
				<option value="2025">2025</option>);
			</select>
		);
	}
	
	renderPayReceiveDropdown() {

		var payYN = this.state.payYN;
		var receiveYN = this.state.receiveYN;
		var value = "";
		
		if (payYN && !receiveYN) {
			value = "pay";
		}
		
		if (!payYN && receiveYN) {
			value = "receive";
		}
		
		var payString = this.props.getLang(RecentLang, "paid");
		var receiveString = this.props.getLang(RecentLang, "received");
		
		return (
			<select 
				id="searchPayReceive" 
				key="searchPayReceive"
				value={value}
				onChange={(event) => this.onPayReceiveChanged(event.target.value)}
				style={{ padding: '8px', width: '100px' }}
			>
				<option value=""></option>);
				<option value="pay">{payString}</option>);
				<option value="receive">{receiveString}</option>);
			</select>
		);
	}
	
	renderBankNameDropdown() {

		var bankName = this.state.bankName;
		
		return (
			<select 
				id="searchBankName" 
				key="searchBankName"
				value={bankName}
				onChange={(event) => this.onBankNameChanged(event.target.value)}
				style={{ padding: '8px', width: '100px' }}
			>
				<option value=""></option>);
				<option value="erste">Erste banka</option>);
				<option value="pbz">PBZ banka</option>);
			</select>
		);
	}
	
	renderSearch() {
		
		var searchText = this.state.searchText;
		var minAmount = this.state.minAmount;
		var minPriceTotal = this.state.minPriceTotal;
		var searchStatementCount = this.state.searchStatementCount;
		var payAmountSum = this.state.payAmountSum;
		var receiveAmountSum = this.state.receiveAmountSum;

		var payString = "";
		if (payAmountSum > 0) {
			payString = " - paid: " + this.props.getEURString(payAmountSum, 2);
		}

		var receiveString = "";
		if (receiveAmountSum > 0) {
			receiveString = " - received: " + this.props.getEURString(receiveAmountSum, 2);
		}
		
		return (
			<div className="main-container">
				{this.renderYearDropdown()}
				{this.renderPayReceiveDropdown()}
				{this.renderBankNameDropdown()}
				<input
					type="text"
					placeholder="Search..."
					value={searchText}
					onChange={(event) => this.onSearchTextChanged(event.target.value)}
					style={{ padding: '8px', width: '200px' }}
				/>
				<input
					type="number"
					placeholder="Min Amount..."
					value={minAmount}
					onChange={(event) => this.onMinAmountChanged(event.target.value)}
					style={{ padding: '8px', width: '100px' }}
				/>
				<div>{searchStatementCount} statements {payString} {receiveString}</div>
			</div>
		)
	}

	handlePrev() {
		var index = this.state.index;
		var count = this.state.count;
		index = index - count;
		this.setState({index: index});
		this.serverSearchBankStatementList(index, count, this.state.selectedYear, this.state.bankName, this.state.searchText, this.state.minAmount, this.state.payYN, this.state.receiveYN);
	}

	handleNext() {
		var index = this.state.index;
		var count = this.state.count;
		index = index + count;
		this.setState({index: index});
		this.serverSearchBankStatementList(index, count, this.state.selectedYear, this.state.bankName, this.state.searchText, this.state.minAmount, this.state.payYN, this.state.receiveYN);
	}
	
	handleShowAllStatements() {
		var index = 0;
		var count = 100000000;
		this.setState({
			index: index,
			count: count,
			showAllYN: true,
		});
		this.serverSearchBankStatementList(index, count, this.state.selectedYear, this.state.bankName, this.state.searchText, this.state.minAmount, this.state.payYN, this.state.receiveYN);
	}
	
	downloadPartnersFindPartnerObj(partnerArray, iban1) {

		var i;
		var j;
		var itemObj1;
		var itemObj2;
		var ibanArray;
		var iban2;
		
		for(i=0; i<partnerArray.length; i++) {
			itemObj1 = partnerArray[i];
			ibanArray = itemObj1.ibanArray;
			for(j=0; j<ibanArray.length; j++) {
				itemObj2 = ibanArray[j];
				iban2 = itemObj2.iban;
				if (iban1 === iban2) {
					return itemObj1;
				}
			}
		}
		return null;
	}
	
	findIbanInIbanArrayYN(iban1, ibanArray) {
		
		if (ibanArray === null) {
			return false;
		}
		
		var i;
		var itemObj;
		var iban2;
		
		for(i=0; i<ibanArray.length; i++) {
			itemObj = ibanArray[i];
			iban2 = itemObj.iban;
			if (iban1 === iban2) {
				return true;
			}
		}
		
		return false;
	}

	downloadPartnersAddStatementsToWorksheet(jsonObj, itemObjEx, partnerName, ibanArray, worksheet, row) {

		var i;
		var itemObj;
		var language = this.props.language;
		var partnerType = itemObjEx.type;
		var sellerNameERP;
		var cellRef;
		var cellObj;
		var fillObj = {
			type: 'pattern',
			pattern: 'solid',
			fgColor: { argb: 'EEEEEE' },
		};
		
		cellRef = "A" + row;
		cellObj = worksheet.getCell(cellRef);
		cellObj.value = "date";
		cellObj.font = { bold: true };
		cellObj.fill = fillObj;
		
		cellRef = "B" + row;
		cellObj = worksheet.getCell(cellRef);
		cellObj.value = "description";
		cellObj.font = { bold: true };
		cellObj.fill = fillObj;
		
		cellRef = "C" + row;
		cellObj = worksheet.getCell(cellRef);
		cellObj.value = "payAmount";
		cellObj.font = { bold: true };
		cellObj.fill = fillObj;
		cellObj.alignment = { horizontal: 'right' };
		
		cellRef = "D" + row;
		cellObj = worksheet.getCell(cellRef);
		cellObj.value = "receiveAmount";
		cellObj.font = { bold: true };
		cellObj.fill = fillObj;
		cellObj.alignment = { horizontal: 'right' };
		
		cellRef = "E" + row;
		cellObj = worksheet.getCell(cellRef);
		cellObj.value = "reason";
		cellObj.font = { bold: true };
		cellObj.fill = fillObj;
		cellObj.alignment = { horizontal: 'center' };
		
		cellRef = "F" + row;
		cellObj = worksheet.getCell(cellRef);
		cellObj.value = "iban";
		cellObj.font = { bold: true };
		cellObj.fill = fillObj;
		
		cellRef = "G" + row;
		cellObj = worksheet.getCell(cellRef);
		cellObj.value = "transactionID";
		cellObj.font = { bold: true };
		cellObj.fill = fillObj;
		
		cellRef = "H" + row;
		cellObj = worksheet.getCell(cellRef);
		cellObj.value = "bank";
		cellObj.font = { bold: true };
		cellObj.fill = fillObj;
		
		row ++;
		
		var dateString = "";
		var row1 = row;

		var iban2 = "";
		var bankName = "";
		var partnerCity = "";
		var description = "";
		var payAmount = "";
		var receiveAmount = "";
		var paymentReference = "";
		var transactionID = "";
		var executeDay = "";
		var executeMonth = "";
		var executeYear = "";
		var nameFoundInBillYN = false;
		var billID = "";
		var count = 0;

		for(i=0; i<jsonObj.length; i++) {

			itemObj = jsonObj[i];

			if (itemObj.payAmount === 0) {
				iban2 = itemObj.payerIBAN;
			} else {
				iban2 = itemObj.receiverIBAN;
			}

			if (this.findIbanInIbanArrayYN(iban2, ibanArray)) {

				executeDay = itemObj.executeDay;
				executeMonth = itemObj.executeMonth;
				executeYear = itemObj.executeYear;
				dateString = executeDay + "." + executeMonth + "." + executeYear;

				bankName = itemObj.bankName;
				partnerCity = itemObj.partnerCity;
				description = itemObj.description;
				payAmount = itemObj.payAmount;
				receiveAmount = itemObj.receiveAmount;
				paymentReference = itemObj.paymentReference;
				transactionID = itemObj.transactionID;

				nameFoundInBillYN = itemObj.nameFoundInBillYN;
				billID = itemObj.billID;

				if (language === "hr") {
					//priceTotalERP = this.convertFloatToValueWithColon(priceTotalERP);
				}
				
				cellRef = "A" + row;
				cellObj = worksheet.getCell(cellRef);
				cellObj.value = dateString;

				cellRef = "B" + row;
				cellObj = worksheet.getCell(cellRef);
				cellObj.value = description;

				cellRef = "C" + row;
				cellObj = worksheet.getCell(cellRef);
				cellObj.value = payAmount;
				if (language === "hr") {
					cellObj.numFmt = '#.##0,00 "€"';
				} else {
					cellObj.numFmt = '#,##0.00 "€"';
				}

				cellRef = "D" + row;
				cellObj = worksheet.getCell(cellRef);
				cellObj.value = receiveAmount;
				if (language === "hr") {
					cellObj.numFmt = '#.##0,00 "€"';
				} else {
					cellObj.numFmt = '#,##0.00 "€"';
				}

				var reasonText = partnerType;
				var billText = "";
				if (nameFoundInBillYN) {
					if (billID === -1) {
						billText = "no bill";
					} else {
						billText = "billID: " + billID;
					}
					if (reasonText === "") {
						reasonText = billText;
					} else {
						reasonText = reasonText + " - " + billText;
					}
				}

				cellRef = "E" + row;
				cellObj = worksheet.getCell(cellRef);
				cellObj.value = reasonText;
				cellObj.alignment = { horizontal: 'center' };

				cellRef = "F" + row;
				cellObj = worksheet.getCell(cellRef);
				cellObj.value = iban2;

				cellRef = "G" + row;
				cellObj = worksheet.getCell(cellRef);
				cellObj.value = transactionID;

				cellRef = "H" + row;
				cellObj = worksheet.getCell(cellRef);
				cellObj.value = bankName;
				
				row ++;
				count ++;

			}
		}
		
		var row2 = row - 1;
		var formulaString = "";

		if (count > 0) {

			cellRef = "A" + row;
			cellObj = worksheet.getCell(cellRef);
			cellObj.value = "Total:";
			cellObj.font = { bold: true };

			cellRef = "C" + row;
			cellObj = worksheet.getCell(cellRef);
			formulaString = "SUM(C" + row1 + ":C" + row2 + ")";
			cellObj.value = { formula: formulaString };
			cellObj.font = { bold: true };
			if (language === "hr") {
				cellObj.numFmt = '#.##0,00 "€"';
			} else {
				cellObj.numFmt = '#,##0.00 "€"';
			}

			cellRef = "D" + row;
			cellObj = worksheet.getCell(cellRef);
			formulaString = "SUM(D" + row1 + ":D" + row2 + ")";
			cellObj.value = { formula: formulaString };
			cellObj.font = { bold: true };
			if (language === "hr") {
				cellObj.numFmt = '#.##0,00 "€"';
			} else {
				cellObj.numFmt = '#,##0.00 "€"';
			}
		}

		row ++;
		row ++;

		return row;
	}

	// niksa
	worksheetAddBankStatements(workbook, jsonObj, partnerArrayEx, partnerType1, worksheetTitle) {

		var worksheet = workbook.addWorksheet(worksheetTitle);
		
		worksheet.columns = [
			{ key: 'A', width: 10 },
			{ key: 'B', width: 40 },
			{ key: 'C', width: 15 },
			{ key: 'D', width: 15 },
			{ key: 'E', width: 30 },
			{ key: 'F', width: 30 },
			{ key: 'G', width: 30 },
			{ key: 'H', width: 10 },
		];

		var i;
		var row = 1;
		var itemObjEx;
		var partnerName;
		var partnerType2;
		var payTotal;
		var ibanArray;
		var ibanString;
		var nameArray;
		var nameString;
		var cellRef;
		var cellObj;
		var includeYN;
		
		for(i=0; i<partnerArrayEx.length; i++) {

			itemObjEx = partnerArrayEx[i];
			partnerName = itemObjEx.name;
			partnerType2 = itemObjEx.type;
			payTotal = itemObjEx.payTotal;
			
			includeYN = false;
			if (partnerType1 === "") {
				includeYN = true;
			}
			if (partnerType1 === partnerType2) {
				includeYN = true;
			}
			if (partnerType1 === "bill") {
				if (payTotal > 0) {
					if (partnerType2 === "") {
						includeYN = true;
					}
				}
			}
			
			if (includeYN) {
				ibanArray = itemObjEx.ibanArray;
				ibanString = itemObjEx.ibanString;
				nameArray = itemObjEx.nameArray;
				nameString = itemObjEx.nameString;
				cellRef = "A" + row;
				cellObj = worksheet.getCell(cellRef);
				cellObj.value = partnerName + " - " + ibanString;
				if (nameString !== "") {
					if (nameString !== partnerName) {
						cellObj.value += " - " + nameString;
					}
				}
				cellObj.font = { 
					bold: true,
					color: { argb: '0000FF' },
				};
				row ++;
				row = this.downloadPartnersAddStatementsToWorksheet(jsonObj, itemObjEx, partnerName, ibanArray, worksheet, row);
			}
		}
		
	}

	worksheetAddFilters(worksheet) {

		var selectedYear = this.state.selectedYear;
		var searchText = this.state.searchText;
		var minAmount = this.state.minAmount;
		var bankName = this.state.bankName;
		var payYN = this.state.payYN;
		var receiveYN = this.state.receiveYN;
		var email = this.props.email;
		var fullName = this.props.fullName;

		var today = new Date();
		var year = today.getFullYear();
		var month = today.getMonth() + 1;
		var day = today.getDate();		
		var dateString = day + "." + month + "." + year;
		var hour = today.getHours();
		var minute = today.getMinutes();
		var second = today.getSeconds();
		var timeString = hour + ":" + this.props.getTwoDigits(minute) + ":" + this.props.getTwoDigits(second);

		var payReceiveString = "Paid and received"
		if ((payYN) && (!receiveYN)) {
			payReceiveString = "Paid only";
		}
		if ((!payYN) && (receiveYN)) {
			payReceiveString = "Received only";
		}
		
		var row = 1;
		var cellObj;

		cellObj = worksheet.getCell("A" + row);
		cellObj.value = "Year:";
		cellObj.font = { bold: true };

		cellObj = worksheet.getCell("B" + row);
		cellObj.value = selectedYear;

		row ++;
		
		cellObj = worksheet.getCell("A" + row);
		cellObj.value = "Search:";
		cellObj.font = { bold: true };

		cellObj = worksheet.getCell("B" + row);
		cellObj.value = searchText;

		row ++;
		
		cellObj = worksheet.getCell("A" + row);
		cellObj.value = "Min amount:";
		cellObj.font = { bold: true };

		cellObj = worksheet.getCell("B" + row);
		cellObj.value = minAmount;
		cellObj.alignment = { horizontal: 'left' };

		row ++;
		
		cellObj = worksheet.getCell("A" + row);
		cellObj.value = "Bank name:";
		cellObj.font = { bold: true };

		cellObj = worksheet.getCell("B" + row);
		cellObj.value = bankName;
		cellObj.alignment = { horizontal: 'left' };

		row ++;
		
		cellObj = worksheet.getCell("A" + row);
		cellObj.value = "Paid/received:";
		cellObj.font = { bold: true };

		cellObj = worksheet.getCell("B" + row);
		cellObj.value = payReceiveString;
		cellObj.alignment = { horizontal: 'left' };

		row ++;
		
		cellObj = worksheet.getCell("A" + row);
		cellObj.value = "User:";
		cellObj.font = { bold: true };

		cellObj = worksheet.getCell("B" + row);
		cellObj.value = fullName + " (" + email + ")";

		row ++;
		
		cellObj = worksheet.getCell("A" + row);
		cellObj.value = "Date:";
		cellObj.font = { bold: true };

		cellObj = worksheet.getCell("B" + row);
		cellObj.value = dateString;

		row ++;
		
		cellObj = worksheet.getCell("A" + row);
		cellObj.value = "Time:";
		cellObj.font = { bold: true };

		cellObj = worksheet.getCell("B" + row);
		cellObj.value = timeString;

		row ++;
	}

	downloadPartnersChangeNameFromBankIfAvailable(name1, nameArray) {
		
		var bankYN = false;
		var name1Upper = name1.toUpperCase();
		
		if (name1Upper.indexOf("ADDIKO BANK") !== -1) {
			bankYN = true;
		}
		
		if (name1Upper.indexOf("OTP BANKA") !== -1) {
			bankYN = true;
		}
		
		if (name1Upper.indexOf("ERSTE&STEIERMÄRKISCHE BANK") !== -1) {
			bankYN = true;
		}
		
		if (name1Upper.indexOf("RAIFFEISEN BANK") !== -1) {
			bankYN = true;
		}
		
		if (name1Upper.indexOf("ZAGREBAČKA BANKA") !== -1) {
			bankYN = true;
		}
		
		if (name1Upper.indexOf("PBZ") !== -1) {
			bankYN = true;
		}
		
		if (name1Upper.indexOf("HRVATSKA POŠTANSKA BANKA") !== -1) {
			bankYN = true;
		}

		if (name1Upper.indexOf("ERSTE BANK") !== -1) {
			bankYN = true;
		}
		
		if (!bankYN) {
			return name1;
		}
		
		if (nameArray.length === 0) {
			return name1;
		}

		var i;
		var itemObj = nameArray[0];
		var name2 = itemObj.name;

		return name2;
	}
	
	async handleDownloadPartners() {

		var jsonObj = this.state.jsonObj;
		if (jsonObj === null) {
			this.props.showToast("No partners found");
			return;
		}

		var partnerIbanHashMap = this.state.partnerIbanHashMap;
		var i;
		var j;
		var itemObj1;
		var itemObj2;
		var itemObj3;
		var itemObjEx;
		var length = jsonObj.length;
		var partnerName;
		var partnerType;
		var payAmount;
		var receiveAmount;
		var iban;
		var partnerArray = [];
		var row = 0;
		var formulaString = "";
		var ibanArray;
		var nameArray;
		var employeeYN;
		var governmentYN;
		var investmentYN;

		if (length === 0) {
			this.props.showToast("No partners found");
			return;
		}
		
		for(i=0; i<length; i++) {

			itemObj1 = jsonObj[i];
			partnerName = itemObj1.partnerName;
			payAmount = itemObj1.payAmount;
			receiveAmount = itemObj1.receiveAmount;
			if (payAmount === 0) {
				iban = itemObj1.payerIBAN;
			} else {
				iban = itemObj1.receiverIBAN;
			}
			iban = iban.trim();
			if (iban === "") {
				itemObj2 = null;
			} else {
				 itemObj2 = this.downloadPartnersFindPartnerObj(partnerArray, iban);
			}
			
			if (itemObj2 === null) {
				itemObj3 = partnerIbanHashMap[iban];
				if (iban === "HR7125000093208069694") {
				}
				ibanArray = null;
				nameArray = null;
				employeeYN = false;
				governmentYN = false;
				investmentYN = false;
				if (typeof itemObj3 !== "undefined") {
					ibanArray = itemObj3.ibanArray;
					nameArray = itemObj3.nameArray;
					employeeYN = itemObj3.employeeYN;
					governmentYN = itemObj3.governmentYN;
					investmentYN = itemObj3.investmentYN;
				}
				if (ibanArray === null) {
					ibanArray = [];
					ibanArray.push(iban);
				}
				if (nameArray === null) {
					nameArray = [];
					nameArray.push(partnerName);
				}
				partnerType = "";
				if (employeeYN) {
					partnerType = "employee";
				}
				if (governmentYN) {
					partnerType = "government";
				}
				if (investmentYN) {
					partnerType = "investment";
				}
				itemObj2 = {};
				itemObj2.name = partnerName;
				itemObj2.payTotal = payAmount;
				itemObj2.receiveTotal = receiveAmount;
				itemObj2.statementCount = 1;
				itemObj2.type = partnerType;
				itemObj2.ibanArray = ibanArray;
				itemObj2.nameArray = nameArray;
				partnerArray.push(itemObj2);
			} else {
				itemObj2.payTotal += payAmount;
				itemObj2.receiveTotal += receiveAmount;
				itemObj2.statementCount ++;
			}
		}
		
		// malo kompliciranije napravit "." u "," zbog sorta --> nakon sorta
		var language = this.props.language;
		
		// deep copy using JSON methods
		var partnerArrayEx = JSON.parse(JSON.stringify(partnerArray));
		var partnerArray1 = JSON.parse(JSON.stringify(partnerArray));
		var ibanString;
		var nameString;
		var name;
		
		// worksheet 1

		for(i=0; i<partnerArray1.length; i++) {
			itemObj1 = partnerArray1[i];
			itemObjEx = partnerArrayEx[i];
			itemObj1.payTotal = Math.round(itemObj1.payTotal * 100) / 100;
			itemObj1.receiveTotal = Math.round(itemObj1.receiveTotal * 100) / 100;
			if (language === "hr") {
				itemObj1.payTotal = this.convertFloatToValueWithColon(itemObj1.payTotal);
				itemObj1.receiveTotal = this.convertFloatToValueWithColon(itemObj1.receiveTotal);
			}
			itemObjEx.payTotal = itemObj1.payTotal;
			itemObjEx.receiveTotal = itemObj1.receiveTotal;
			itemObjEx.statementCount = itemObj1.statementCount;
			itemObjEx.type = itemObj1.type;
			itemObjEx.ibanArray = itemObj1.ibanArray;
			itemObjEx.nameArray = itemObj1.nameArray;
			ibanArray = itemObj1.ibanArray;
			ibanString = "";
			for(j=0; j<ibanArray.length; j++) {
				itemObj2 = ibanArray[j];
				iban = itemObj2.iban;
				ibanString = ibanString + " " + iban;
				ibanString = ibanString.trim();
			}
			itemObj1.ibanArray = ibanString;
			nameArray = itemObj1.nameArray;
			nameString = "";
			for(j=0; j<nameArray.length; j++) {
				itemObj2 = nameArray[j];
				name = itemObj2.name;
				nameString = nameString + " " + name;
				nameString = nameString.trim();
			}
			itemObj1.nameArray = nameString;
			itemObj1.name = this.downloadPartnersChangeNameFromBankIfAvailable(itemObj1.name, nameArray);
			if (itemObj1.name === nameString) {
				itemObj1.nameArray = "";
			}
			itemObjEx.name = itemObj1.name;
			itemObjEx.ibanString = ibanString;
			itemObjEx.nameString = nameString;
		}

		partnerArray1.sort((a, b) => a.name.localeCompare(b.name));		
		partnerArrayEx.sort((a, b) => a.name.localeCompare(b.name));		

		var cellRef;
		var cellObj;
		var workbook = new ExcelJS.Workbook();

		var worksheet1 = workbook.addWorksheet("Partners");
		var columns = Object.keys(partnerArray1[0]).map((key) => ({ header: key, key: key }));	
		worksheet1.columns = columns;
		worksheet1.columns = [
			{ key: 'A', width: 40 },
			{ key: 'B', width: 20 },
			{ key: 'C', width: 20 },
			{ key: 'D', width: 20 },
			{ key: 'E', width: 20 },
			{ key: 'F', width: 40 },
			{ key: 'G', width: 40 },
		];
		partnerArray1.forEach((itemObj) => {
			worksheet1.addRow(itemObj);
		});
	
		cellObj = worksheet1.getCell("A1");
		cellObj.font = { bold: true };

		cellObj = worksheet1.getCell("B1");
		cellObj.font = { bold: true };
		cellObj.alignment = { horizontal: 'right' };

		cellObj = worksheet1.getCell("C1");
		cellObj.font = { bold: true };
		cellObj.alignment = { horizontal: 'right' };

		cellObj = worksheet1.getCell("D1");
		cellObj.font = { bold: true };
		cellObj.alignment = { horizontal: 'right' };

		cellObj = worksheet1.getCell("E1");
		cellObj.font = { bold: true };

		cellObj = worksheet1.getCell("F1");
		cellObj.font = { bold: true };

		cellObj = worksheet1.getCell("G1");
		cellObj.font = { bold: true };

		row = partnerArray1.length + 2;

		cellRef = "A" + row;
		cellObj = worksheet1.getCell(cellRef);
		cellObj.value = "Total:";
		cellObj.font = { bold: true };

		cellRef = "B" + row;
		cellObj = worksheet1.getCell(cellRef);
		formulaString = "SUM(B2:B" + (partnerArray1.length+1) + ")";
		cellObj.value = { formula: formulaString };
		cellObj.font = { bold: true };

		cellRef = "C" + row;
		cellObj = worksheet1.getCell(cellRef);
		formulaString = "SUM(C2:C" + (partnerArray1.length+1) + ")";
		cellObj.value = { formula: formulaString };
		cellObj.font = { bold: true };

		cellRef = "D" + row;
		cellObj = worksheet1.getCell(cellRef);
		formulaString = "SUM(D2:D" + (partnerArray1.length+1) + ")";
		cellObj.value = { formula: formulaString };
		cellObj.font = { bold: true };
		
		for(i=2; i<=partnerArray1.length+2; i++) {
			cellRef = "B" + i;
			cellObj = worksheet1.getCell(cellRef);
			if (language === "hr") {
				cellObj.numFmt = '#.##0,00 "€"';
			} else {
				cellObj.numFmt = '#,##0.00 "€"';
			}
		}

		for(i=2; i<=partnerArray1.length+2; i++) {
			cellRef = "C" + i;
			cellObj = worksheet1.getCell(cellRef);
			if (language === "hr") {
				cellObj.numFmt = '#.##0,00 "€"';
			} else {
				cellObj.numFmt = '#,##0.00 "€"';
			}
		}

		// worksheet 2

		var partnerArray2 = JSON.parse(JSON.stringify(partnerArray1));
		partnerArray2.sort((a, b) => b.payTotal - a.payTotal);
		for(i=0; i<partnerArray2.length; i++) {
			itemObj1 = partnerArray2[i];
			itemObj1.payTotal = Math.round(itemObj1.payTotal * 100) / 100;
			itemObj1.receiveTotal = Math.round(itemObj1.receiveTotal * 100) / 100;
			if (language === "hr") {
				itemObj1.payTotal = this.convertFloatToValueWithColon(itemObj1.payTotal);
				itemObj1.receiveTotal = this.convertFloatToValueWithColon(itemObj1.receiveTotal);
			}
		}
		var worksheet2 = workbook.addWorksheet("Partners - by payTotal");
		columns = Object.keys(partnerArray2[0]).map((key) => ({ header: key, key: key }));	
		worksheet2.columns = columns;
		worksheet2.columns = [
			{ key: 'A', width: 40 },
			{ key: 'B', width: 20 },
			{ key: 'C', width: 20 },
			{ key: 'D', width: 20 },
			{ key: 'E', width: 20 },
			{ key: 'F', width: 40 },
			{ key: 'G', width: 40 },
		];
		partnerArray2.forEach((itemObj) => {
			worksheet2.addRow(itemObj);
		});
		
		cellObj = worksheet2.getCell("A1");
		cellObj.font = { bold: true };

		cellObj = worksheet2.getCell("B1");
		cellObj.font = { bold: true };
		cellObj.alignment = { horizontal: 'right' };

		cellObj = worksheet2.getCell("C1");
		cellObj.font = { bold: true };
		cellObj.alignment = { horizontal: 'right' };

		cellObj = worksheet2.getCell("D1");
		cellObj.font = { bold: true };
		cellObj.alignment = { horizontal: 'right' };

		cellObj = worksheet2.getCell("E1");
		cellObj.font = { bold: true };

		cellObj = worksheet2.getCell("F1");
		cellObj.font = { bold: true };

		cellObj = worksheet2.getCell("G1");
		cellObj.font = { bold: true };

		row = partnerArray2.length + 2;

		cellRef = "A" + row;
		cellObj = worksheet2.getCell(cellRef);
		cellObj.value = "Total:";
		cellObj.font = { bold: true };

		cellRef = "B" + row;
		cellObj = worksheet2.getCell(cellRef);
		formulaString = "SUM(B2:B" + (partnerArray2.length+1) + ")";
		cellObj.value = { formula: formulaString };
		cellObj.font = { bold: true };

		cellRef = "C" + row;
		cellObj = worksheet2.getCell(cellRef);
		formulaString = "SUM(C2:C" + (partnerArray2.length+1) + ")";
		cellObj.value = { formula: formulaString };
		cellObj.font = { bold: true };

		cellRef = "D" + row;
		cellObj = worksheet2.getCell(cellRef);
		formulaString = "SUM(D2:D" + (partnerArray2.length+1) + ")";
		cellObj.value = { formula: formulaString };
		cellObj.font = { bold: true };

		for(i=2; i<=partnerArray2.length+2; i++) {
			cellRef = "B" + i;
			cellObj = worksheet2.getCell(cellRef);
			if (language === "hr") {
				cellObj.numFmt = '#.##0,00 "€"';
			} else {
				cellObj.numFmt = '#,##0.00 "€"';
			}
		}

		for(i=2; i<=partnerArray2.length+2; i++) {
			cellRef = "C" + i;
			cellObj = worksheet2.getCell(cellRef);
			if (language === "hr") {
				cellObj.numFmt = '#.##0,00 "€"';
			} else {
				cellObj.numFmt = '#,##0.00 "€"';
			}
		}

		// worksheet 3

		var partnerArray3 = JSON.parse(JSON.stringify(partnerArray1));
		partnerArray3.sort((a, b) => b.receiveTotal - a.receiveTotal);
		for(i=0; i<partnerArray3.length; i++) {
			itemObj1 = partnerArray3[i];
			itemObj1.payTotal = Math.round(itemObj1.payTotal * 100) / 100;
			itemObj1.receiveTotal = Math.round(itemObj1.receiveTotal * 100) / 100;
			if (language === "hr") {
				itemObj1.payTotal = this.convertFloatToValueWithColon(itemObj1.payTotal);
				itemObj1.receiveTotal = this.convertFloatToValueWithColon(itemObj1.receiveTotal);
			}
		}
		var worksheet3 = workbook.addWorksheet("Partners - by receiveTotal");
		columns = Object.keys(partnerArray3[0]).map((key) => ({ header: key, key: key }));	
		worksheet3.columns = columns;
		worksheet3.columns = [
			{ key: 'A', width: 40 },
			{ key: 'B', width: 20 },
			{ key: 'C', width: 20 },
			{ key: 'D', width: 20 },
			{ key: 'E', width: 20 },
			{ key: 'F', width: 40 },
			{ key: 'G', width: 40 },
		];
		partnerArray3.forEach((itemObj) => {
			worksheet3.addRow(itemObj);
		});
		
		cellObj = worksheet3.getCell("A1");
		cellObj.font = { bold: true };

		cellObj = worksheet3.getCell("B1");
		cellObj.font = { bold: true };
		cellObj.alignment = { horizontal: 'right' };

		cellObj = worksheet3.getCell("C1");
		cellObj.font = { bold: true };
		cellObj.alignment = { horizontal: 'right' };

		cellObj = worksheet3.getCell("D1");
		cellObj.font = { bold: true };
		cellObj.alignment = { horizontal: 'right' };

		cellObj = worksheet3.getCell("E1");
		cellObj.font = { bold: true };

		cellObj = worksheet3.getCell("F1");
		cellObj.font = { bold: true };

		cellObj = worksheet3.getCell("G1");
		cellObj.font = { bold: true };

		row = partnerArray3.length + 2;

		cellRef = "A" + row;
		cellObj = worksheet3.getCell(cellRef);
		cellObj.value = "Total:";
		cellObj.font = { bold: true };

		cellRef = "B" + row;
		cellObj = worksheet3.getCell(cellRef);
		formulaString = "SUM(B2:B" + (partnerArray3.length+1) + ")";
		cellObj.value = { formula: formulaString };
		cellObj.font = { bold: true };

		cellRef = "C" + row;
		cellObj = worksheet3.getCell(cellRef);
		formulaString = "SUM(C2:C" + (partnerArray3.length+1) + ")";
		cellObj.value = { formula: formulaString };
		cellObj.font = { bold: true };

		cellRef = "D" + row;
		cellObj = worksheet3.getCell(cellRef);
		formulaString = "SUM(D2:D" + (partnerArray3.length+1) + ")";
		cellObj.value = { formula: formulaString };
		cellObj.font = { bold: true };

		for(i=2; i<=partnerArray3.length+2; i++) {
			cellRef = "B" + i;
			cellObj = worksheet3.getCell(cellRef);
			if (language === "hr") {
				cellObj.numFmt = '#.##0,00 "€"';
			} else {
				cellObj.numFmt = '#,##0.00 "€"';
			}
		}

		for(i=2; i<=partnerArray3.length+2; i++) {
			cellRef = "C" + i;
			cellObj = worksheet3.getCell(cellRef);
			if (language === "hr") {
				cellObj.numFmt = '#.##0,00 "€"';
			} else {
				cellObj.numFmt = '#,##0.00 "€"';
			}
		}

		this.worksheetAddBankStatements(workbook, jsonObj, partnerArrayEx, "", "Bank Statements (BS) all");
		this.worksheetAddBankStatements(workbook, jsonObj, partnerArrayEx, "bill", "BS bills");
		this.worksheetAddBankStatements(workbook, jsonObj, partnerArrayEx, "employee", "BS employees");
		this.worksheetAddBankStatements(workbook, jsonObj, partnerArrayEx, "government", "BS government");
		this.worksheetAddBankStatements(workbook, jsonObj, partnerArrayEx, "investment", "BS investment");
		
		// worksheet filters

		var worksheetFilters = workbook.addWorksheet("Filters");
		worksheetFilters.columns = [
			{ key: 'A', width: 20 },
			{ key: 'B', width: 50 },
		];
		this.worksheetAddFilters(worksheetFilters);
	
		var excelBuffer = await workbook.xlsx.writeBuffer();		
		var blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

		var selectedYear = this.state.selectedYear;
		var dateString = this.getTodayDateString();

		var filename = dateString;
		filename = filename + " " + selectedYear;
		filename = filename + " - Bank Statements - Partners.xlsx";
		saveAs(blob, filename);
	}
		
	resetCount() {
		var count = 100;
		this.setState({
			count: count,
			showAllYN: false,
		});
		return count;
	}
	
	getBillArrayFromPartnerName(billNameHashMap, partnerNameHashMap, partnerName) {

		var i;
		var itemObj1;
		var itemObj2;
		var name;
		var nameArray;
		var billArray;
		var partnerName2 = partnerName.toUpperCase();

		billArray = billNameHashMap[partnerName2];
		if (typeof billArray !== "undefined") {
			return billArray;
		}

		itemObj1 = partnerNameHashMap[partnerName2];
		if (typeof itemObj1 === "undefined") {
			return null;
		}
		nameArray = itemObj1.nameArray;
		for(i=0; i<nameArray.length; i++) {
			itemObj2 = nameArray[i];
			name = itemObj2.name;
			name = name.toUpperCase();
			billArray = billNameHashMap[name];
			if (typeof billArray !== "undefined") {
				return billArray;
			}
		}

		return null;
	}
	
	checkIfBillWithAmount(billIDUsedHashMap, billArray, payAmount, executeDay, executeMonth, executeYear) {

		var i;
		var itemObj;
		var priceTotalERP;
		var dayERP;
		var monthERP;
		var year;
		var billID;
		
		var date1 = new Date(executeYear, executeMonth, executeDay);
		var date2;
		var diffSec;
		var index = -1;
		var minDiffSec = -1;
		var foundYN;
		
		for(i=0; i<billArray.length; i++) {
			itemObj = billArray[i];
			billID = itemObj.billID;
			foundYN = billIDUsedHashMap[billID];
			if (typeof foundYN === "undefined") {
				foundYN = false;
			}
			if (!foundYN) {
				priceTotalERP = itemObj.priceTotalERP;
				if (payAmount === priceTotalERP) {
					dayERP = itemObj.dayERP;
					monthERP = itemObj.monthERP;
					year = itemObj.year;
					date2 = new Date(year, monthERP, dayERP);
					diffSec = Math.abs(date2 - date1) / 1000;
					if (minDiffSec === -1) {
						index = i;
						minDiffSec = diffSec;
					} else {
						if (diffSec < minDiffSec) {
							index = i;
							minDiffSec = diffSec;
						}
					}
				}
			}
		}
		
		if (index === -1) {
			return -1;
		}

		itemObj = billArray[index];
		billID = itemObj.billID;

		return billID;
	}

	renderOneBankStatement(itemObj) {

		var partnerIbanHashMap = this.state.partnerIbanHashMap;
		var partnerNameHashMap = this.state.partnerNameHashMap;
		var billNameHashMap = this.state.billNameHashMap;

		var bankStatementID = itemObj.bankStatementID;
		var bankName = itemObj.bankName;
		var partnerName = itemObj.partnerName;
		var partnerCity = itemObj.partnerCity;
		var description = itemObj.description;
		var payAmount = itemObj.payAmount;
		var receiveAmount = itemObj.receiveAmount;
		var paymentReference = itemObj.paymentReference;
		var payerIBAN = itemObj.payerIBAN;
		var receiverIBAN = itemObj.receiverIBAN;
		var executeDay = itemObj.executeDay;
		var executeMonth = itemObj.executeMonth;
		var executeYear = itemObj.executeYear;
		var transactionID = itemObj.transactionID;
		var partnerObj = itemObj.partnerObj;
		var nameFoundInBillYN = itemObj.nameFoundInBillYN;
		var billID = itemObj.billID;
		var key = bankStatementID + "-bankstatement";

		var iban = "";
		if (payAmount === 0) {
			iban = payerIBAN;
		} else {
			iban = receiverIBAN;
		}
		
		var ibanClassName = "RecentBankStatementsIBANItem";

		var employeeYN = false;
		var governmentYN = false;
		var investmentYN = false;

		var partnerName2 = partnerName.toUpperCase();
		
		var i;
		var name;
		var itemObj2;
		var nameArray;
		
		if (partnerObj !== null) {
			if (partnerObj.employeeYN) {
				ibanClassName = "RecentBankStatementsIBANItemEmployee";
				employeeYN = true;
			}
			if (partnerObj.governmentYN) {
				ibanClassName = "RecentBankStatementsIBANItemGovernment";
				governmentYN = true;
			}
			if (partnerObj.investmentYN) {
				ibanClassName = "RecentBankStatementsIBANItemInvestment";
				investmentYN = true;
			}

			nameArray = partnerObj.nameArray;
			for(i=0; i<nameArray.length; i++) {
				itemObj2 = nameArray[i];
				name = itemObj2.name;
				name = name.toUpperCase();
				if (name !== partnerName2) {
					partnerName = partnerName + " - " + name;
				}
			}
		}

		if (!employeeYN) {
			if (nameFoundInBillYN) {
				ibanClassName = "RecentBankStatementsIBANItemBill1";
				if (billID > 0) {
					ibanClassName = "RecentBankStatementsIBANItemBill2";
				}
			}
		}

		var dateString = executeDay + "." + executeMonth + "." + executeYear;
		var payAmountString = this.props.getEURString(payAmount, 2);
		var receiveAmountString = this.props.getEURString(receiveAmount, 2);

		var jsxCodeIban = (<div>{iban}</div>);
		if (billID > 0) {
			jsxCodeIban = (<div>{iban}<br/>Bill ID: {billID}</div>);
		}
		
		var jsxCode = (		
			<tr key={key} className="">
				<td className="RecentBankStatementsDateItem">{dateString}</td>
				<td className="RecentBankStatementsPartnerItem">{partnerName}</td>
				<td className="RecentBankStatementsDescriptionItem">{description}</td>
				<td className="RecentBankStatementsPaidItem">{payAmountString}</td>
				<td className="RecentBankStatementsReceivedItem">{receiveAmountString}</td>
				<td className={ibanClassName}>{jsxCodeIban}</td>
				<td className="RecentBankStatementsTransactionItem">{bankName} / {transactionID}</td>
			</tr>
		);
		
		return jsxCode;
	}

	renderSelectedBankStatements() {

		var jsonObj = this.state.jsonObj;
		if (jsonObj === null) {
			return null;
		}
		
		var i;
		var length = jsonObj.length;
		var itemObj;
		var jsxCode;
		var jsxArray = [];
		var openYN;
		var priceTotalERP;
		var total = 0;
		
		for(i=0; i<length; i++) {
			itemObj = jsonObj[i];
			jsxCode = this.renderOneBankStatement(itemObj);
			jsxArray.push(jsxCode);
		}

		return (
			<div>
				<table>
					<tbody>
						<tr key="header">
							<td className="RecentBankStatementsDateHeader">{this.props.getLang(RecentLang, "date")}</td>
							<td className="RecentBankStatementsPartnerHeader">{this.props.getLang(RecentLang, "poslovniPartner")}</td>
							<td className="RecentBankStatementsDescriptionHeader">{this.props.getLang(RecentLang, "description")}</td>
							<td className="RecentBankStatementsPaidHeader">{this.props.getLang(RecentLang, "paid")}</td>
							<td className="RecentBankStatementsReceivedHeader">{this.props.getLang(RecentLang, "received")}</td>
							<td className="RecentBankStatementsIBANHeader">{this.props.getLang(RecentLang, "iban")}</td>
							<td className="RecentBankStatementsTransactionHeader">{this.props.getLang(RecentLang, "transationID")}</td>
						</tr>
						{jsxArray}
					</tbody>
				</table>
			</div>
		);
	}

	renderPrevNext() {

		var jsonObj = this.state.jsonObj;
		if (jsonObj === null) {
			return null;
		}

		var showAllYN = this.state.showAllYN;
		if (showAllYN) {
			return null;
		}

		var billCount = jsonObj.length;
		var index = this.state.index;
		var count = this.state.count;

		var prevYN = false;
		var nextYN = false;
		
		if (this.state.index > 0) {
			prevYN = true;
		}
		
		if (billCount === count) {
			nextYN = true;
		}

		return (
			<div className="ListContainer3">
				{prevYN && (<div 
					className="ListPrevNextLink"
					onClick={() => this.handlePrev()}
				>
					&lt;&lt; Previous
				</div>)}
				{!prevYN && (<div 
					className="ListPrevNextLinkDisabled"
				>
					&lt;&lt; Previous
				</div>)}
				<div className="ListPrevNextLinkDisabled">|</div>
				{nextYN && (<div 
					className="ListPrevNextLink"
					onClick={() => this.handleNext()}
				>
					Next &gt;&gt;
				</div>)}
				{!nextYN && (<div 
					className="ListPrevNextLinkDisabled"
				>
					Next &gt;&gt;
				</div>)}
				<div 
					className="ListPrevNextLink"
				>
				</div>
				<div 
					className="RecentBankStatementsShowAll"
					onClick={() => this.handleShowAllStatements()}
				>
					Show All Statements
				</div>
			</div>
		)
	}
	
	calcStatementSummary() {
		
		var paySumArray = [0,0,0,0,0,0,0,0,0,0,0,0,0];
		var receiveSumArray = [0,0,0,0,0,0,0,0,0,0,0,0,0];

		var jsonObj = this.state.jsonObj;
		if (jsonObj === null) {
			return [paySumArray, receiveSumArray];
		}

		var i;
		var itemObj;
		var length = jsonObj.length;
		var payAmount;
		var receiveAmount;
		var executeMonth;

		for(i=0; i<length; i++) {
			itemObj = jsonObj[i];
			payAmount = itemObj.payAmount;
			receiveAmount = itemObj.receiveAmount;
			executeMonth = itemObj.executeMonth;
			if ((executeMonth < 1) || (executeMonth > 12)) {
				paySumArray[0] += payAmount;
				receiveSumArray[0] += receiveAmount;
			} else {
				paySumArray[executeMonth] += payAmount;
				receiveSumArray[executeMonth] += receiveAmount;
			}
		}
		
		return [paySumArray, receiveSumArray];
	}

	renderOneStatementMonthly(index, paySumValue, receiveSumValue) {
		
		var monthString = "";
		var key = index + "-monthly";
		var paySumValueString = this.props.getEURString(paySumValue, 2);
		var receiveSumValueString = this.props.getEURString(receiveSumValue, 2);
		var selectedYear = this.state.selectedYear;
		
		if (index === 0) {
			monthString = "Missing month";
			if ((paySumValue === 0) && (receiveSumValue === 0)) {
				return null;
			}
		} else {
			var month = this.props.getTwoDigits(index);
			monthString = month + "/" + selectedYear;
		}
		
		return (
			<tr key={key}>
				<td className="RecentBankStatementsMonthlyMonthItem">{monthString}</td>
				<td className="RecentBankStatementsMonthlySumItem">{paySumValueString}</td>
				<td className="RecentBankStatementsMonthlySumItem">{receiveSumValueString}</td>
			</tr>
		);
	}

	renderStatementMonthly() {

		var showAllYN = this.state.showAllYN;
		if (!showAllYN) {
			return null;
		}
		
		var paySumArray;
		var receiveSumArray 
		
		[paySumArray, receiveSumArray] = this.calcStatementSummary();
		
		var i;
		var paySumValue;
		var receiveSumValue;
		var jsxCode;
		var jsxArray = [];
		var totalPaySum = 0;
		var totalReceiveSum = 0;
		
		for(i=0; i<paySumArray.length; i++) {
			paySumValue = paySumArray[i];
			receiveSumValue = receiveSumArray[i];
			totalPaySum += paySumValue;
			totalReceiveSum += receiveSumValue;
			jsxCode = this.renderOneStatementMonthly(i, paySumValue, receiveSumValue);
			jsxArray.push(jsxCode);
		}
		
		var totalPaySumString = this.props.getEURString(totalPaySum, 2);
		var totalReceiveSumString = this.props.getEURString(totalReceiveSum, 2);
		
		return (
			<div>
				<hr/>
				<div>
					<div style={{height: "5px"}}></div>
					<table className="RecentBankStatementsMonthlyTable">
						<tr>
							<td className="RecentBankStatementsMonthlyMonthHeader">{this.props.getLang(RecentLang, "month")}</td>
							<td className="RecentBankStatementsMonthlySumHeader">{this.props.getLang(RecentLang, "totalPaid")}</td>
							<td className="RecentBankStatementsMonthlySumHeader">{this.props.getLang(RecentLang, "totalReceived")}</td>
						</tr>
						{jsxArray}
						<tr>
							<td></td>
							<td className="RecentBankStatementsMonthlySumItem"><b>{totalPaySumString}</b></td>
							<td className="RecentBankStatementsMonthlySumItem"><b>{totalReceiveSumString}</b></td>
						</tr>
					</table>
				</div>
			</div>
		);
	}

	renderDownloadButtons() {

		var showAllYN = this.state.showAllYN;
		if (!showAllYN) {
			return null;
		}

		return (
			<div>
				<hr/>
				<div style={{height: "5px"}}></div>
				<div className="RecentBankStatementsDownloadContainter">
					<div className="RecentBankStatementsDownloadLink" onClick={() => this.handleDownloadPartners()}>
						Download partners
					</div>
				</div>
			</div>
		);
	}
		
	render() {
		return (
			<div>
				<div style={{height: "5px"}}></div>
				{this.renderSearch()}
				<div style={{height: "5px"}}></div>
				{this.renderSelectedBankStatements()}
				<div style={{height: "5px"}}></div>
				{this.renderPrevNext()}
				<div style={{height: "5px"}}></div>
				{this.renderStatementMonthly()}
				<div style={{height: "50px"}}></div>
				{this.renderDownloadButtons()}
				<div style={{height: "50px"}}></div>
			</div>
		);
	}
}

export default RecentBankStatementList;

