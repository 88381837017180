import React  from 'react';
import './App.css';
import './Recent.css';
import RecentLang from './RecentLang.json';
import { GoTriangleRight, GoTriangleDown, GoPlus } from "react-icons/go";

class RecentPartnerList extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			partnerArray: null,
			partnerCount: 0,
			nameCount: 0,
			ibanCount: 0,
			employeeCount: 0,
			newPartnerName: "",
			businessUnitListJsonObj: null,
			placeExpenditureListJsonObj: null,
		};
	}

	async componentDidMount() {

		await this.serverGetPartnerList();

		var companyID = this.props.companyID;
		this.serverGetBusinessUnitList(companyID);
		this.serverGetPlaceExpenditureList(companyID);
	}
	
	findInIbanListYN(ibanArray, partnerIbanID1) {

		var i;
		var itemObj;
		var partnerIbanID2;
		
		for(i=0; i<ibanArray.length; i++) {
			itemObj = ibanArray[i];
			partnerIbanID2 = itemObj.partnerIbanID;
			if (partnerIbanID1 === partnerIbanID2) {
				return true;
			}
		}
		
		return false;
	}

	findInNameListYN(nameArray, partnerNameID1) {

		var i;
		var itemObj;
		var partnerNameID2;
		
		for(i=0; i<nameArray.length; i++) {
			itemObj = nameArray[i];
			partnerNameID2 = itemObj.partnerNameID;
			if (partnerNameID1 === partnerNameID2) {
				return true;
			}
		}
		
		return false;
	}

	async serverGetPartnerList() {

		var companyID = this.props.companyID;		
		var baseURLAPI = this.props.baseURLAPI;

		var url = baseURLAPI + "/getpartnerlist?companyid=" + companyID;
		
		var res = await fetch(url);
		var jsonObj = await res.json();
		
		var i;
		var itemObj1;
		var partnerIDPrev;
		var partnerID;
		var employeeYN;
		var governmentYN;
		var investmentYN;
		var partnerIbanID;
		var iban;
		var partnerNameID;
		var name;
		var partnerArray = [];
		var itemObj2;
		var itemObj3;
		var length = jsonObj.length;
		
		partnerIDPrev = 0;
		
		for(i=0; i<length; i++) {
			itemObj1 = jsonObj[i];
			partnerID = itemObj1.partnerID;
			employeeYN = itemObj1.employeeYN;
			governmentYN = itemObj1.governmentYN;
			investmentYN = itemObj1.investmentYN;
			partnerIbanID = itemObj1.partnerIbanID;
			iban = itemObj1.iban;
			partnerNameID = itemObj1.partnerNameID;
			name = itemObj1.name;
			if (partnerID === partnerIDPrev) {
				if (!this.findInIbanListYN(itemObj2.ibanArray, partnerIbanID)) {
					if (iban !== null) {
						itemObj3 = {};
						itemObj3.partnerIbanID = partnerIbanID;
						itemObj3.iban = iban;
						itemObj2.ibanArray.push(itemObj3);
					}
				}
				if (!this.findInNameListYN(itemObj2.nameArray, partnerNameID)) {
					if (name !== null) {
						itemObj3 = {};
						itemObj3.partnerNameID = partnerNameID;
						itemObj3.name = name;
						itemObj2.nameArray.push(itemObj3);
					}
				}
			} else {
				partnerIDPrev = partnerID;
				itemObj2 = {};
				itemObj2.partnerID = partnerID;
				if (employeeYN === 1) {
					itemObj2.employeeYN = true;
				} else {
					itemObj2.employeeYN = false;
				}
				if (governmentYN === 1) {
					itemObj2.governmentYN = true;
				} else {
					itemObj2.governmentYN = false;
				}
				if (investmentYN === 1) {
					itemObj2.investmentYN = true;
				} else {
					itemObj2.investmentYN = false;
				}
				itemObj2.openYN = false;
				itemObj2.ibanArray = [];
				if (iban !== null) {
					itemObj3 = {};
					itemObj3.partnerIbanID = partnerIbanID;
					itemObj3.iban = iban;
					itemObj2.ibanArray.push(itemObj3);
				}
				itemObj2.nameArray = [];
				if (name !== null) {
					itemObj3 = {};
					itemObj3.partnerNameID = partnerNameID;
					itemObj3.name = name;
					itemObj2.nameArray.push(itemObj3);
				}
				partnerArray.push(itemObj2);
			}
		}
		
		partnerArray.sort((a, b) => {

			var nameArray1 = a.nameArray;
			var nameArray2 = b.nameArray;
			var name1 = "";
			var name2 = "";
			
			if (nameArray1.length > 0) {
				name1 = nameArray1[0].name;
			}
			if (nameArray2.length > 0) {
				name2 = nameArray2[0].name;
			}
			
			return name1.localeCompare(name2);
		});
		
		var nameArray;
		var ibanArray;
		var partnerCount = partnerArray.length;
		var nameCount = 0;
		var ibanCount = 0;
		var employeeCount = 0;
		
		for(i=0; i<partnerArray.length; i++) {
			itemObj1 = partnerArray[i];
			if (itemObj1.employeeYN) {
				employeeCount ++;
			}
			nameArray = itemObj1.nameArray;
			ibanArray = itemObj1.ibanArray;
		}
		
		this.setState({
			partnerArray: partnerArray,
			partnerCount: partnerCount,
			nameCount: nameCount,
			ibanCount: ibanCount,
			employeeCount: employeeCount,
		});
	}

	async serverGetBusinessUnitList(companyID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/company/" + companyID + "/businessUnit.json";

		var jsonObj = null;

		try {
			var res = await fetch(url);
			jsonObj = await res.json();
		} catch (err) {
		}

		this.setState({businessUnitListJsonObj: jsonObj});
	}

	async serverGetPlaceExpenditureList(companyID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/company/" + companyID + "/placeOfExpenditure.json";

		var jsonObj = null;

		try {
			var res = await fetch(url);
			jsonObj = await res.json();
		} catch (err) {
		}
		
		this.setState({placeExpenditureListJsonObj: jsonObj});
	}

	async serverSetPartnerBusinessUnit(partnerID, businessUnit) {

		var baseURLAPI = this.props.baseURLAPI;

		var url = baseURLAPI + "/setpartnerbusinessunit?partnerid=" + partnerID;
		url = url + "&businessunit=" + businessUnit;
		console.log(url);

		var res = await fetch(url);
		await res.text();
	}

	async serverSetPartnerPlaceOfExpenditure(partnerID, placeOfExpenditure) {

		var baseURLAPI = this.props.baseURLAPI;

		var url = baseURLAPI + "/setpartnerplaceofexpenditure?partnerid=" + partnerID;
		url = url + "&placeofexpenditure=" + placeOfExpenditure;
		console.log(url);

		var res = await fetch(url);
		await res.text();
	}

	async serverSetPartnerAllYN(partnerID, employeeYN, governmentYN, investmentYN) {

		var baseURLAPI = this.props.baseURLAPI;

		var url = baseURLAPI + "/setpartnerallyn?partnerid=" + partnerID;
		if (employeeYN) {
			url = url + "&employeeyn=1";
		} else {
			url = url + "&employeeyn=0";
		}
		if (governmentYN) {
			url = url + "&governmentyn=1";
		} else {
			url = url + "&governmentyn=0";
		}
		if (investmentYN) {
			url = url + "&investmentyn=1";
		} else {
			url = url + "&investmentyn=0";
		}

		var res = await fetch(url);
		await res.text();
	}
	
	async serverInsertPartnerRecord() {

		var companyID = this.props.companyID;
		var baseURLAPI = this.props.baseURLAPI;

		var url = baseURLAPI + "/insertpartnerrecord?companyid=" + companyID;

		var res = await fetch(url);
		var partnerID = await res.text();
		partnerID = parseInt(partnerID);
		
		return partnerID;
	}
	
	async serverDeletePartnerRecord(partnerID) {

		var baseURLAPI = this.props.baseURLAPI;

		var url = baseURLAPI + "/deletepartnerrecord?partnerid=" + partnerID;

		var res = await fetch(url);
		await res.text();
	}
	
	async serverSetPartnerIban(partnerIbanID, iban) {

		var baseURLAPI = this.props.baseURLAPI;

		var url = baseURLAPI + "/setpartneriban?partneribanid=" + partnerIbanID;
		url = url + "&iban=" + encodeURIComponent(iban);

		var res = await fetch(url);
		await res.text();
	}
		
	async serverSetPartnerName(partnerNameID, name) {

		var baseURLAPI = this.props.baseURLAPI;

		var url = baseURLAPI + "/setpartnername?partnernameid=" + partnerNameID;
		url = url + "&name=" + encodeURIComponent(name);

		var res = await fetch(url);
		await res.text();
	}
	
	async serverInsertPartnerIbanRecord(partnerID, iban) {

		var companyID = this.props.companyID;
		var baseURLAPI = this.props.baseURLAPI;

		var url = baseURLAPI + "/insertpartneribanrecord?companyid=" + companyID;		
		url = url + "&partnerid=" + partnerID;
		url = url + "&iban=" + encodeURIComponent(iban);

		var res = await fetch(url);
		var partnerIbanID = await res.text();
		partnerIbanID = parseInt(partnerIbanID);

		return partnerIbanID;
	}
	
	async serverDeletePartnerIbanRecord(partnerIbanID) {

		var baseURLAPI = this.props.baseURLAPI;

		var url = baseURLAPI + "/deletepartneribanrecord?partneribanid=" + partnerIbanID;

		var res = await fetch(url);
		await res.text();
	}
	
	async serverInsertPartnerNameRecord(partnerID, name) {

		var companyID = this.props.companyID;
		var baseURLAPI = this.props.baseURLAPI;

		var url = baseURLAPI + "/insertpartnernamerecord?companyid=" + companyID;		
		url = url + "&partnerid=" + partnerID;
		url = url + "&name=" + encodeURIComponent(name);

		var res = await fetch(url);
		var partnerNameID = await res.text();
		partnerNameID = parseInt(partnerNameID);

		return partnerNameID;
	}
	
	async serverDeletePartnerNameRecord(partnerNameID) {

		var baseURLAPI = this.props.baseURLAPI;

		var url = baseURLAPI + "/deletepartnernamerecord?partnernameid=" + partnerNameID;

		var res = await fetch(url);
		await res.text();
	}
	
	handleIconClick(index) {

		var partnerArray = this.state.partnerArray;
		var itemObj = partnerArray[index];
		var openYN = itemObj.openYN;
		openYN = !openYN;
		itemObj.openYN = openYN;

		this.setState({
			partnerArray: partnerArray,
		});
	}
	
	handleEmployeeChange(index, value) {

		var partnerArray = this.state.partnerArray;
		var itemObj = partnerArray[index];
		var partnerID = itemObj.partnerID;

		itemObj.employeeYN = value;
		itemObj.governmentYN = false;
		itemObj.investmentYN = false;

		this.setState({
			partnerArray: partnerArray,
		});
				
		this.serverSetPartnerAllYN(partnerID, value, false, false);
	}
	
	handleGovernmentChange(index, value) {

		var partnerArray = this.state.partnerArray;
		var itemObj = partnerArray[index];
		var partnerID = itemObj.partnerID;

		itemObj.employeeYN = false;
		itemObj.governmentYN = value;
		itemObj.investmentYN = false;

		this.setState({
			partnerArray: partnerArray,
		});
				
		this.serverSetPartnerAllYN(partnerID, itemObj.employeeYN, itemObj.governmentYN, itemObj.investmentYN);
	}
	
	handleInvestmentChange(index, value) {

		var partnerArray = this.state.partnerArray;
		var itemObj = partnerArray[index];
		var partnerID = itemObj.partnerID;

		itemObj.employeeYN = false;
		itemObj.governmentYN = false;
		itemObj.investmentYN = value;

		this.setState({
			partnerArray: partnerArray,
		});
				
		this.serverSetPartnerAllYN(partnerID, itemObj.employeeYN, itemObj.governmentYN, itemObj.investmentYN);
	}
	
	async handleIbanBlur(index1, index2, value) {

		var iban = value.trim();
		var partnerArray = this.state.partnerArray;
		var itemObj1 = partnerArray[index1];
		var partnerID = itemObj1.partnerID;
		var ibanArray = itemObj1.ibanArray;
		
		var itemObj2 = null;
		var partnerIbanID1 = 0;

		if (index2 !== -1) {
			itemObj2 = ibanArray[index2];
			partnerIbanID1 = itemObj2.partnerIbanID;
		}
		
		var i;
		var partnerIbanID2;
		var setDBYN = false;
		
		if (iban === "") {

			if (index2 !== -1) {

				var ibanArray2 = [];
				for(i=0; i<ibanArray.length; i++) {
					itemObj2 = ibanArray[i];
					partnerIbanID2 = itemObj2.partnerIbanID;
					if (partnerIbanID1 !== partnerIbanID2) {
						ibanArray2.push(itemObj2);
					}
					itemObj1.ibanArray = ibanArray2;
				}

				await this.serverDeletePartnerIbanRecord(partnerIbanID1);
				partnerArray = await this.checkIfPartnerEmpty(partnerArray, index1);

				this.setState({
					partnerArray: partnerArray,
				});
			}
			
		} else {
			
			if (index2 === -1) {

				partnerIbanID2 = await this.serverInsertPartnerIbanRecord(partnerID, iban);
				itemObj2 = {};
				itemObj2.partnerIbanID = partnerIbanID2;
				itemObj2.iban = iban;
				ibanArray.push(itemObj2);
				itemObj1.ibanArray = ibanArray;

				this.setState({
					partnerArray: null,
				});

				await this.serverGetPartnerList();

			} else {

				for(i=0; i<ibanArray.length; i++) {
					itemObj2 = ibanArray[i];
					partnerIbanID2 = itemObj2.partnerIbanID;
					if (partnerIbanID1 === partnerIbanID2) {
						if (itemObj2.iban !== iban) {
							setDBYN = true;
						}
						itemObj2.iban = iban;
					}
				}

				if (setDBYN) {
					this.serverSetPartnerIban(partnerIbanID1, iban);
					this.setState({
						partnerArray: partnerArray,
					});
				}
			}
		}
	}
	
	handleNewPartnerChange(value) {
		this.setState({
			newPartnerName: value,
		});
	}
	
	async handleNewPartnerBlur(value) {

		var name = value.trim();
		var partnerID = await this.serverInsertPartnerRecord();
		var partnerNameID = await this.serverInsertPartnerNameRecord(partnerID, name);

		this.setState({
			newPartnerName: "",
		});

		await this.serverGetPartnerList();
	}

	async checkIfPartnerEmpty(partnerArray, index1) {
	
		var itemObj1 = partnerArray[index1];
		var partnerID = itemObj1.partnerID;
		var nameArray = itemObj1.nameArray;
		var ibanArray = itemObj1.ibanArray;

		if (nameArray.length > 0) {
			return partnerArray;
		}

		if (ibanArray.length > 0) {
			return partnerArray;
		}

		var i;
		var partnerArray2 = [];
		var itemObj2;
		
		for(i=0; i<partnerArray.length; i++) {
			itemObj2 = partnerArray[i];
			if (i !== index1) {
				partnerArray2.push(itemObj2);
			}
		}
		
		await this.serverDeletePartnerRecord(partnerID);

		return partnerArray2;
	}
	
	async handleNameBlur(index1, index2, value) {
		
		var name = value.trim();
		var partnerArray = this.state.partnerArray;
		var itemObj1 = partnerArray[index1];
		var partnerID = itemObj1.partnerID;
		var nameArray = itemObj1.nameArray;

		var itemObj2 = null;
		var partnerNameID1 = 0;

		if (index2 !== -1) {
			itemObj2 = nameArray[index2];
			partnerNameID1 = itemObj2.partnerNameID;
		}
		
		var i;
		var partnerNameID2;
		var setDBYN = false;
		
		if (name === "") {

			if (index2 !== -1) {

				var nameArray2 = [];
				for(i=0; i<nameArray.length; i++) {
					itemObj2 = nameArray[i];
					partnerNameID2 = itemObj2.partnerNameID;
					if (partnerNameID1 !== partnerNameID2) {
						nameArray2.push(itemObj2);
					}
					itemObj1.nameArray = nameArray2;
				}

				await this.serverDeletePartnerNameRecord(partnerNameID1);
				partnerArray = await this.checkIfPartnerEmpty(partnerArray, index1);

				this.setState({
					partnerArray: partnerArray,
				});
			}
			
		} else {
		
			if (index2 === -1) {

				partnerNameID2 = await this.serverInsertPartnerNameRecord(partnerID, name);
				itemObj2 = {};
				itemObj2.partnerNameID = partnerNameID2;
				itemObj2.name = name;
				nameArray.push(itemObj2);
				itemObj1.nameArray = nameArray;

				this.setState({
					partnerArray: null,
				});

				await this.serverGetPartnerList();

			} else {

				for(i=0; i<nameArray.length; i++) {
					itemObj2 = nameArray[i];
					partnerNameID2 = itemObj2.partnerNameID;
					if (partnerNameID1 === partnerNameID2) {
						if (itemObj2.name !== name) {
							setDBYN = true;
						}
						itemObj2.name = name;
					}
				}

				if (setDBYN) {
					await this.serverSetPartnerName(partnerNameID1, name);
					this.setState({
						partnerArray: partnerArray,
					});
				}
			}
		}
	}
	
	renderOneEmptyPartner() {
		
		var key1 = "newpartner-tr";
		var newPartnerName = this.state.newPartnerName;

		return (
			<tr key={key1} className="RecentPartnerListTrOpen">
				<td className="RecentPartnerListIconItem"><GoPlus /></td>
				<td className="RecentPartnerListNamesItem">
				<input className="RecentPartnerListEditNameInput"
					value={newPartnerName}
					onChange={(e) => this.handleNewPartnerChange(e.target.value)}
					onBlur={(e) => this.handleNewPartnerBlur(e.target.value)}
				/>
				</td>
				<td className="RecentPartnerListIbansItem"></td>
				<td className="RecentPartnerListTypeItem"></td>
			</tr>

		);
	}
	
	onBusinessUnitChanged(value) {
		var businessUnitID = parseInt(value);
		return;
		this.setState({
			index: 0,
			placeExpenditureID: "",
			businessUnitID: businessUnitID
		});
		var count = this.resetCount();
		this.serverSearchBillList(this.state.index, count, this.state.selectedYear, this.state.searchText, this.state.minPriceTotal, businessUnitID, this.state.placeExpenditureID);
	}

	onPlaceExpenditureChanged(value) {
		var placeExpenditureID = value;
		this.setState({
			index: 0,
			placeExpenditureID: placeExpenditureID
		});
		var count = this.resetCount();
		this.serverSearchBillList(this.state.index, count, this.state.selectedYear, this.state.searchText, this.state.minPriceTotal, this.state.businessUnitID, placeExpenditureID);
	}

	renderBusinessUnitDropdown(index, businessUnitID) {
		
		var jsonObj = this.state.businessUnitListJsonObj;

		if (jsonObj === null) {
			return null;
		}

		var i;
		var key1 = index + "-businessUnit";
		var key2;
		var itemObj;
		var id;
		var itemText;
		var dashboardID2;
		var jsxCode;
		var jsxArray = [];

		for(i=0; i<jsonObj.length; i++) {

			itemObj = jsonObj[i];
			id = itemObj.id;
			itemText = itemObj.text;
			key2 = index + "-" + i + "-businessUnit";
			
			jsxCode = (<option key={key2} value={id}>{itemText}</option>);
			jsxArray.push(jsxCode);
		}

		var jsxCodeEmpty = (<option value={0}></option>);

		return (
			<select 
				id="dropdown" 
				key={key1}
				value={businessUnitID}
				onChange={(event) => this.onBusinessUnitChanged(event.target.value)}
				style={{ padding: '8px', width: '120px' }}
			>
				{jsxCodeEmpty}
				{jsxArray}
			</select>
		);
	}

	renderPlaceExpenditureDropdown() {
		
		var jsonObj = this.state.placeExpenditureListJsonObj;

		if (jsonObj === null) {
			return null;
		}

		var placeExpenditureID = this.state.placeExpenditureID;
		var dashboardID1 = this.props.dashboardID;
		var businessUnitID1 = this.state.businessUnitID;

		var dashboardAppUserType = this.props.dashboardAppUserType;
		var dashboardUserType = this.props.dashboardUserType;
		var allBillsYN = this.props.allBillsYN;

		var i;
		var key;
		var itemObj;
		var id;
		var itemText;
		var dashboardID2;
		var businessUnitID2;
		var jsxCode;
		var jsxArray = [];
		var addYN;
		var emptyYN = false;

		for(i=0; i<jsonObj.length; i++) {

			itemObj = jsonObj[i];
			id = itemObj.id;
			itemText = itemObj.text;
			dashboardID2 = itemObj.dashboardID;
			businessUnitID2 = itemObj.businessUnitID;
			key = i + "-placeOfExpenditure";
			
			addYN = false;
			
			if (dashboardID1 === dashboardID2) {

				if (dashboardAppUserType === 99) {
					addYN = true;
					emptyYN = true;
				}

				if (dashboardAppUserType >= 100) {
					addYN = true;
					emptyYN = true;
				}

				if (dashboardAppUserType === 1) {

					if (dashboardUserType < 1000) {
						addYN = true;
						emptyYN = true;
					}
					
					if (dashboardUserType === 1001) {
					}

					if (dashboardUserType === 1002) {
						if ((id === "2") || (id === "3") || (id === "9")) {
							addYN = true;
						}
					}

					if (dashboardUserType === 1003) {
						if ((id === "4") || (id === "5") || (id === "6")) {
							addYN = true;
						}
					}

					if (dashboardUserType === 1004) {
						if (id === "10") {
							addYN = true;
						}
					}
				}
			}
			
			if (allBillsYN) {
				
				if (businessUnitID1 === 0) {
					addYN = true;
				}

				if (businessUnitID1 === businessUnitID2) {
					addYN = true;
				}

				emptyYN = true;
			}

			if (addYN) {
				jsxCode = (<option key={key} value={id}>{itemText}</option>);
				jsxArray.push(jsxCode);
			}
		}

		var jsxCodeEmpty = null;
		if (emptyYN) {
			jsxCodeEmpty = (<option value=""></option>);
		}

		return (
			<select 
				id="dropdown" 
				key="placeOfExpenditure"
				value={placeExpenditureID}
				onChange={(event) => this.onPlaceExpenditureChanged(event.target.value)}
				style={{ padding: '8px', width: '150px' }}
			>
				{jsxCodeEmpty}
				{jsxArray}
			</select>
		);
	}
	
	renderTwoDropdowns(index, partnerID, businessUnit, placeOfExpenditure) {

		var key = index + "-dropdowns";

		return (
			<div key={key}>
				{this.renderBusinessUnitDropdown(index, businessUnit)};
				second dropdown
			</div>
		);
	}
	
	renderOnePartner(index, itemObj) {
		
		var partnerID = itemObj.partnerID;
		var openYN = itemObj.openYN;
		var employeeYN = itemObj.employeeYN;
		var governmentYN = itemObj.governmentYN;
		var investmentYN = itemObj.investmentYN;
		var ibanArray = itemObj.ibanArray;
		var nameArray = itemObj.nameArray;
		var businessUnit = itemObj.businessUnit;
		var placeOfExpenditure = itemObj.placeOfExpenditure;
		var i;
		var key1 = index + "-tr";
		var key2;
		var iban;
		var name;
		var jsxCode;
		var jsxArrayIban = [];
		var jsxArrayName = [];
		var itemObj3;
		var partnerNameID;
		var partnerIbanID;
		
		var jsxCodeTriangle = (<GoTriangleRight />);

		if (openYN) {
			jsxCodeTriangle = (<GoTriangleDown />);
		}
		
		ibanArray.map((itemObj3, index2) => {
			partnerIbanID = itemObj3.partnerIbanID;
			iban = itemObj3.iban;
			key2 = partnerIbanID + "-iban";
			if (openYN) {
				jsxCode = (
					<input className="RecentPartnerListEditIbanInput" 
						key={key2}
						defaultValue={iban} 
						onBlur={(e) => this.handleIbanBlur(index, index2, e.target.value)}
					/>
				);
			} else {
				jsxCode = (<div key={key2}>{iban}</div>);
			}
			jsxArrayIban.push(jsxCode);
		});

		if (openYN) {
			key2 = index + "-ibanex";
			jsxCode = (
				<input className="RecentPartnerListEditIbanInput"
					key={key2}
					defaultValue=""
					onBlur={(e) => this.handleIbanBlur(index, -1, e.target.value)}
				/>
			);
			jsxArrayIban.push(jsxCode);
		}
		
		nameArray.map((itemObj3, index2) => {
			name = itemObj3.name;
			partnerNameID = itemObj3.partnerNameID;
			key2 = partnerNameID + "-name";
			if (openYN) {
				jsxCode = (
					<input className="RecentPartnerListEditNameInput" 
						key={key2}
						defaultValue={name} 
						onBlur={(e) => this.handleNameBlur(index, index2, e.target.value)}
					/>
				);
			} else {
				jsxCode = (<div key={key2}>{name}</div>);
			}
			jsxArrayName.push(jsxCode);
		});
		
		if (openYN) {
			key2 = index + "-nameex";
			jsxCode = (
				<input className="RecentPartnerListEditNameInput"
					key={key2}
					defaultValue=""
					onBlur={(e) => this.handleNameBlur(index, -1, e.target.value)}
				/>
			);
			jsxArrayName.push(jsxCode);
		}
		
		var jsxCodeCheckboxes = null;
		var jsxCodeTwoDropdowns = null;

		if (openYN) {
			key2 = index + "-allyn";
			jsxCodeTwoDropdowns = null;
			if (employeeYN) {
				jsxCodeTwoDropdowns = this.renderTwoDropdowns(index, partnerID, businessUnit, placeOfExpenditure);
			}
			jsxCodeCheckboxes = (
				<div key={key2} className="RecentPartnerListEditEmployeeInput" >
					<input type="checkbox" checked={employeeYN} onChange={(e) => this.handleEmployeeChange(index, e.target.checked)}/> employee<br/>
					<input type="checkbox" checked={governmentYN} onChange={(e) => this.handleGovernmentChange(index, e.target.checked)}/> government<br/>
					<input type="checkbox" checked={investmentYN} onChange={(e) => this.handleInvestmentChange(index, e.target.checked)}/> investment<br/>
					{jsxCodeTwoDropdowns}
				</div>);
		} else {
			if (employeeYN) {
				jsxCodeCheckboxes = (<div>employee</div>);
			}
			if (governmentYN) {
				jsxCodeCheckboxes = (<div>government</div>);
			}
			if (investmentYN) {
				jsxCodeCheckboxes = (<div>investment</div>);
			}
		}
		
		return (
			<tr key={key1} className="RecentPartnerListTrOpen">
				<td className="RecentPartnerListIconItem" onClick={() => this.handleIconClick(index)}>{jsxCodeTriangle}</td>
				<td className="RecentPartnerListNamesItem">{jsxArrayName}</td>
				<td className="RecentPartnerListIbansItem">{jsxArrayIban}</td>
				<td className="RecentPartnerListEmplyeeItem">{jsxCodeCheckboxes}</td>
			</tr>
		);
	}
	
	renderHeader() {

		var partnerCount = this.state.partnerCount;
		var nameCount = this.state.nameCount;
		var ibanCount = this.state.ibanCount;
		var employeeCount =  this.state.employeeCount;
		
		return (
			<div>
				{partnerCount} partners, {employeeCount} employees
			</div>
		);
	}

	renderAllPartners() {

		var partnerArray = this.state.partnerArray;
		if (partnerArray === null) {
			return null;
		}

		var i;
		var itemObj;
		var length = partnerArray.length;
		var jsxCode;
		var jsxArray = [];
		
		jsxCode = this.renderOneEmptyPartner();
		jsxArray.push(jsxCode);

		for(i=0; i<length; i++) {
			itemObj = partnerArray[i];
			jsxCode = this.renderOnePartner(i, itemObj);
			jsxArray.push(jsxCode);
		}
		
		return (
			<table>
				<thead>
					<tr>
						<td className="RecentPartnerListIconHeader"></td>
						<td className="RecentPartnerListNamesHeader">{this.props.getLang(RecentLang, "names")}</td>
						<td className="RecentPartnerListIbansHeader">{this.props.getLang(RecentLang, "ibans")}</td>
						<td className="RecentPartnerListTypeHeader">{this.props.getLang(RecentLang, "type")}</td>
					</tr>
				</thead>
				<tbody>
					{jsxArray}
				</tbody>
			</table>
		);
	}

	render() {
		return (
			<div>
				<div style={{height: "5px"}}></div>
				{this.renderHeader()}
				<div style={{height: "5px"}}></div>
				{this.renderAllPartners()}
				<div style={{height: "50px"}}></div>
			</div>
		);
	}
}

export default RecentPartnerList;

