import React from 'react';
import './App.css';
import './Navbar.css';
import { AiOutlineLogout } from "react-icons/ai"
import { BsCardList, BsDatabaseCheck } from "react-icons/bs"
import { FaInfo, FaChartLine, FaRegChartBar, FaClockRotateLeft } from "react-icons/fa6"
import { IoMdGitCompare } from "react-icons/io";
import { CiCalendarDate } from "react-icons/ci";

import AppLang from './AppLang.json';

import { useNavigate } from "react-router-dom";

class Navbar extends React.Component {

	constructor(props) {

		super(props);

		this.handleClick = this.handleClick.bind(this);		
	}
	
	componentDidMount() {
	}

	componentWillUnmount() {
	};
	
	handleClick(page) {

		const navigate = this.props.navigate;

		if (page === "/logout") {
			this.props.logoutClearVariables();
			navigate("/login");
		} else {
			navigate(page);
		}

		this.props.closeNavbar();
	}
	
	render() {
		
		var appUserType = this.props.appUserType;
		var dashboardUserType = this.props.dashboardUserType;
		var compareYN = this.props.compareYN;

		var showEditDashboardYN = false;
		var showExpensesYN = false;

		if (appUserType === 99) {
			showExpensesYN = true;
		}

		if (appUserType >= 100) {
			showEditDashboardYN = true;
			showExpensesYN = true;
		}

		if ((dashboardUserType === 100) || (dashboardUserType === 101) || (dashboardUserType === 102)) {
			showEditDashboardYN = true;
		}
		
		if ((dashboardUserType === 2) || (dashboardUserType === 3) || (dashboardUserType === 101) || (dashboardUserType === 102)) {
			showExpensesYN = true;
		}
		
		var showRecentYN = true;
		if (dashboardUserType > 1000) {
			showRecentYN = false;
		}

		var navbarOpenYN = this.props.navbarOpenYN;
		if (!navbarOpenYN) {
			return (<div></div>);
		}
		
		return (
			<div id="navbar" className="Navbar">

				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/monthly")}
				>
					<div className="NavbarLinkColumn1">
						<FaChartLine style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "monthly")}</div>				  					  
				</div>

				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/yearly/revenue")}
				>
					<div className="NavbarLinkColumn1">
						<FaRegChartBar style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "yearlyRevenue")}</div>				  					  
				</div>

				{showExpensesYN && (<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/yearly/expenses")}
				>
					<div className="NavbarLinkColumn1">
						<FaRegChartBar style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "yearlyExpenses")}</div>				  					  
				</div>)}

				{/*<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/yearly/earnings")}
				>
					<div className="NavbarLinkColumn1">
						<FaRegChartBar style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "yearlyEarnings")}</div>				  					  
				</div>*/}
				
				{showRecentYN && (<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/recent")}
				>
					<div className="NavbarLinkColumn1">
						<FaClockRotateLeft style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "recentView")}</div>				  					  
				</div>)}

				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/reports")}
				>
					<div className="NavbarLinkColumn1">
						<CiCalendarDate style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "reportsView")}</div>				  					  
				</div>

				{compareYN && (<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/compare")}
				>
					<div className="NavbarLinkColumn1">
						<IoMdGitCompare style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "compareView")}</div>				  					  
				</div>)}

				{showEditDashboardYN && (<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/edit/daily")}
				>
					<div className="NavbarLinkColumn1">
						<BsCardList style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "edit")}</div>
				</div>)}

				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/selectdashboard")}
				>
					<div className="NavbarLinkColumn1">
						<BsDatabaseCheck style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "selectDashboard")}</div>
				</div>

				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/about")}
				>
					<div className="NavbarLinkColumn1">
						<FaInfo style={{ color: "white", width: "25px", height: "20px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "about")}</div>				  					  
				</div>

				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/logout")}
				>
					<div className="NavbarLinkColumn1">
						<AiOutlineLogout style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">Logout</div>
				</div>

			</div>
		);
	}
}

export default function NavbarFunction(props) {
	const navigate = useNavigate();
	return <Navbar {...props} navigate={navigate} />;
}

