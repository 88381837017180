import React  from 'react';
import './App.css';
import './DashboardMain.css';
import DashboardLang from './DashboardLang.json';

class DashboardMainRevenue extends React.Component {

	constructor(props) {

		super(props);

		this.getValues = this.getValues.bind(this);
		this.createItemObjForRoomRevenue = this.createItemObjForRoomRevenue.bind(this);
		this.createItemObjForTotalRevenue = this.createItemObjForTotalRevenue.bind(this);		
		this.renderOneBox = this.renderOneBox.bind(this);
		this.renderAllBoxes = this.renderAllBoxes.bind(this);
	}
	
	getValues(dashboardVariableListJsonObj, variableName1) {

		var i;
		var data1 = [];
		var daysInMonth = this.props.daysInMonth;
		
		for(i=0; i<daysInMonth; i++) {
			data1.push(0);
		}

		var itemObj;
		var variableName2;
		var variableType = "";
		var jsonObj = null;

		for(i=0; i<dashboardVariableListJsonObj.length; i++) {
			itemObj = dashboardVariableListJsonObj[i];
			variableName2 = itemObj.variableName;
			if (variableName1 === variableName2) {
				variableType = itemObj.variableType;
				jsonObj = itemObj.values;
			}
		}
		
		if (jsonObj === null) {
			return null;
		}

		var day;
		var valueInt;
		var valueFloat;
		
		for(i=0; i<jsonObj.length; i++) {
			itemObj = jsonObj[i];
			day = itemObj.day;
			valueInt = itemObj.valueInt;
			valueFloat = itemObj.valueFloat;
			if (variableType === "int") {
				data1[day-1] = valueInt;
			}
			if (variableType === "float") {
				data1[day-1] = valueFloat;
			}
		}
		
		return data1;
	}

	createItemObjForRoomRevenue(dashboardVariableListJsonObj) {

		var i;
		var itemObj = {};
		var values = [];

		var dataRooms = this.getValues(dashboardVariableListJsonObj, "rooms");
		var dataRoomPrice = this.getValues(dashboardVariableListJsonObj, "roomprice");
		if ((dataRooms !== null) && (dataRoomPrice !== null)) {
			for(i=0; i<dataRooms.length; i++) {
				var rooms = dataRooms[i];
				var roomPrice = dataRoomPrice[i];
				var roomRevenue = rooms * roomPrice;
				var valueObj = {};
				valueObj.valueInt = roomRevenue;
				valueObj.valueFloat = 0;
				values.push(valueObj);
			}
		}


		var compareMonthTotalRoomRevenue = this.props.compareMonthTotalRoomRevenue;

		var itemObj2 = {};
		itemObj2.variableName = "roomrevenue";
		itemObj2.variableType = "int";
		itemObj2.values = values;
		itemObj2.compareMonthTotal = compareMonthTotalRoomRevenue;
		
		return itemObj2;
	}
	
	createItemObjForTotalRevenue(dashboardVariableListJsonObj, itemObjForRoomRevenue) {

		var calcHelpersObj = this.props.calcHelpersObj;
		var i;
		var itemObj = {};
		var values = [];
		var variableName;
		var currencyYN;
		var data;
		var dataArray = [];
		
		for(i=0; i<dashboardVariableListJsonObj.length; i++) {
			itemObj = dashboardVariableListJsonObj[i];
			variableName = itemObj.variableName;
			currencyYN = calcHelpersObj.isVariableCurrencyYN(variableName);
			if (variableName === "roomprice") {
				currencyYN = false;
			}
			if (currencyYN) {
				data = this.getValues(dashboardVariableListJsonObj, variableName);
				dataArray.push(data);
			}
		}
		
		var j;
		var daysInMonth = this.props.daysInMonth;
		var oneValue;
		var sum;

		for(i=0; i<daysInMonth; i++) {
			sum = 0;
			if (itemObjForRoomRevenue !== null) {
				if (itemObjForRoomRevenue.values !== null) {
					if (i < itemObjForRoomRevenue.values.length) {
						sum = itemObjForRoomRevenue.values[i].valueInt;
					}
				}
			}
			for(j=0; j<dataArray.length; j++) {
				data = dataArray[j];
				if (data !== null) {
					oneValue = data[i];
					sum = sum + oneValue;
				}
			}
			var valueObj = {};
			valueObj.valueInt = sum;
			valueObj.valueFloat = 0;
			values.push(valueObj);
		}

		var compareMonthTotalRoomRevenue = this.props.compareMonthTotalRoomRevenue;
		var compareMonthTotalOtherRevenue = this.props.compareMonthTotalOtherRevenue;

		var itemObj2 = {};
		itemObj2.variableName = "totalrevenue";
		itemObj2.variableType = "int";
		itemObj2.values = values;
		itemObj2.compareMonthTotal = compareMonthTotalRoomRevenue + compareMonthTotalOtherRevenue;
		
		return itemObj2;
	}
	
	renderOneBox(itemObj, index) {
		
		var calcHelpersObj = this.props.calcHelpersObj;
		var currentYear = this.props.currentYear;
		var currentMonth = this.props.currentMonth;
		var selectedYear = this.props.selectedYear;
		var selectedMonth = this.props.selectedMonth;
		var variableName = itemObj.variableName;
		var variableType = itemObj.variableType;
		var compareMonthTotal = itemObj.compareMonthTotal;
		
		var skipVariableYN = false;
		if (variableName === "totalrooms") {
			skipVariableYN = true;
		}

		if (skipVariableYN) {
			return null;
		}

		var currencyYN = calcHelpersObj.isVariableCurrencyYN(variableName);
		var currentYearMonthYN = false;
		if ((currentYear === selectedYear) && (currentMonth === selectedMonth)) {
			currentYearMonthYN = true;
		}
		

		var totalRoomsData = null;
		if (variableName === "rooms") {
			totalRoomsData = this.getValues(this.props.dashboardVariableListJsonObj, "totalrooms");
		}

		var i;
		var valueItemObj;
		var values = itemObj.values;
		var count = 0;
		var count2 = 0;
		var sum = 0;
		var valueInt;
		var valueFloat;
		var average = 0;
		var percentageOccupiedSum = 0;
		var percentageOccupiedCount = 0;
		var percentageOccupiedAverage = 0;
		var percentageOccupiedString = "";		
		var totalRooms = this.props.totalRooms;
		
		if (values !== null) {

			count = values.length;

			for(i=0; i<count; i++) {

				valueItemObj = values[i];
				valueInt = valueItemObj.valueInt;
				valueFloat = valueItemObj.valueFloat;

				if (variableType === "int") {
					if ((valueInt > 0) || (!currentYearMonthYN)) {
						sum += valueInt;
						count2 ++;
					}
				}

				if (variableType === "float") {
					if ((valueFloat > 0) || (!currentYearMonthYN)) {
						sum += valueFloat;
						count2 ++;
					}
				}

				var percentageOccupied = 0;
				if (variableName === "rooms") {
					var day = valueItemObj.day;
					if (totalRooms > 0) {
						percentageOccupied = valueInt / totalRooms;
						percentageOccupiedSum += percentageOccupied;
						percentageOccupiedCount ++;
					}
				}
			}
		}
		
		var decimalPlaces = 0;
		if (variableType === "float") {
			decimalPlaces = 2;
		}

		if (count2 > 0) {
			average = Math.round(sum / count2);
		}

		var compareDaysInMonth = this.props.getDaysInMonth(selectedYear-1, selectedMonth);
		var compareMonthAverage = Math.round(compareMonthTotal / compareDaysInMonth);
		var compareMonthAverageString = compareMonthAverage;
		
		var percentageChange = 0;
		var percentageChangeString = "";
		var classNamePercengage = "DashboardMainRevenueBoxPercentageBlack";
		if (compareMonthTotal > 0) {
			percentageChange = average / compareMonthAverage;
			percentageChange = percentageChange - 1;
			percentageChange = Math.round(100 * percentageChange);
			if (percentageChange === 0) {
				percentageChangeString = "+" + percentageChange + " %";
				classNamePercengage = "DashboardMainRevenueBoxPercentageBlack";
			}
			if (percentageChange > 0) {
				percentageChangeString = "+" + percentageChange + " %";
				classNamePercengage = "DashboardMainRevenueBoxPercentageGreen";
			}
			if (percentageChange < 0) {			
				percentageChangeString = percentageChange + " %";
				classNamePercengage = "DashboardMainRevenueBoxPercentageRed";
			}
		}
		
		var variableString;
		variableString = this.props.getLang(DashboardLang, variableName);
		
		var averageString = average;
		var totalString = "Total: " + sum;

		if (currencyYN) {
			averageString = this.props.getEURString(average, decimalPlaces);
			compareMonthAverageString = this.props.getEURString(compareMonthAverage, decimalPlaces);
			totalString = "Total: " + this.props.getEURString(sum, decimalPlaces);
		}
	
		if (variableName === "roomprice") {
			totalString = "";
		}

		if (variableName === "rooms") {
			if (percentageOccupiedCount > 0) {
				percentageOccupiedAverage = 100 * percentageOccupiedSum / percentageOccupiedCount;
				percentageOccupiedString = Math.round(percentageOccupiedAverage);
				totalString = totalString + " - " + this.props.getLang(DashboardLang, "occupied") + ": " + percentageOccupiedString + " %";
			}
		}
		
		var compareYear = selectedYear-1;
		
		return (
			<div key={index} className="DashboardMainRevenueOneBox">
				<div className="DashboardMainRevenueBoxTitle">{variableString}</div>
				<div className="DashboardMainRevenueBoxAverage">{averageString}</div>
				<div className={classNamePercengage}>{percentageChangeString}</div>
				<div className="DashboardMainRevenueBoxCompare">{compareYear}: {compareMonthAverageString}</div>
				<div className="DashboardMainRevenueBoxTotal">{totalString}</div>
			</div>
		);
	}

	renderAllBoxes() {
		
		var dashboardVariableListJsonObj = this.props.dashboardVariableListJsonObj;
		if (dashboardVariableListJsonObj === null) {
			return;
		}
		
		var i;
		var variableName;
		var itemObj;
		var roomsYN = false;
		var roomPriceYN = false;

		for(i=0; i<dashboardVariableListJsonObj.length; i++) {
			itemObj = dashboardVariableListJsonObj[i];
			variableName = itemObj.variableName;
			if (variableName === "rooms") {
				roomsYN = true;
			}
			if (variableName === "roomprice") {
				roomPriceYN = true;
			}
		}

		var roomRevenueYN = false;
		if (roomsYN && roomPriceYN) {
			roomRevenueYN = true;
		}

		var itemObjForRoomRevenue;
		var itemObjForTotalRevenue;
		var jsxCode;
		var jsxArray = [];
		
		itemObjForRoomRevenue = null;
		if (roomRevenueYN) {
			itemObjForRoomRevenue = this.createItemObjForRoomRevenue(dashboardVariableListJsonObj);
		}
		
		var dashboardUserType = this.props.dashboardUserType;

		if (dashboardUserType <= 1001) {
			itemObjForTotalRevenue = this.createItemObjForTotalRevenue(dashboardVariableListJsonObj, itemObjForRoomRevenue);
			jsxCode = this.renderOneBox(itemObjForTotalRevenue, -2);
			jsxArray.push(jsxCode);
		}

		if (roomRevenueYN) {
			jsxCode = this.renderOneBox(itemObjForRoomRevenue, -1);
			jsxArray.push(jsxCode);
		}
		
		for(i=0; i<dashboardVariableListJsonObj.length; i++) {
			itemObj = dashboardVariableListJsonObj[i];
			jsxCode = this.renderOneBox(itemObj, i);
			jsxArray.push(jsxCode);
		}

		return (
			<div className="DashboardMainRevenueBoxContainer">
				{jsxArray}
			</div>
		);
	}
	

	render() {
		return (
			<div className="DashboardMainRevenueTopContainer">
				{this.renderAllBoxes()}
				<div style={{height: "100px"}}></div>
			</div>
		);
	}
}

export default DashboardMainRevenue;

