import React  from 'react';
import './App.css';

import ReportBillList from './ReportBillList.js';

export default function ReportBillListAll(props) {
	return <ReportBillList {...props} allBillsYN={true} />;
}


